import {
  Chart as ChartJS,
  CategoryScale,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TickOptions
} from 'chart.js';
import { Fragment, useEffect, useState, useRef, memo, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

import './style.css';
import {
  AxisDictGraph,
  ColorGraph,
  DataGraph,
  DataGraphYConfig,
  YConfig,
  yLabel
} from '../../../interfaces/graph';
import { ChartContext } from '../../../context/GraphContext';
import { set } from 'firebase/database';
import { findMax, findMin } from '../../../utils';
import { formatNumberToFiveDigits } from '../../../utils/';

// Config chart js
ChartJS.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface ILineGraphSimple {
  _data: DataGraph;
  axisDict: AxisDictGraph;
  onZoom: any;
  backgroundRanges: {
    from: number;
    to: number;
    color: string;
    // onClick: () => void;
  }[];
  title: string;
  showTooltip: boolean;
}

function generateScales(axisDict: AxisDictGraph): any {
  let scales: any = {};
  Object.keys(axisDict).forEach((key: string) => {
    scales[key] = {
      type: axisDict[key].type,
      display: true,
      position: axisDict[key].position,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: any, ticks: any) {
          let valueStr: string = `${value}`;
          const regEx = new RegExp('[0-9]+[.,][0-9]+');
          if (!regEx.test(valueStr)) {
            valueStr = value.toFixed(1);
          }
          return valueStr.replaceAll(',', '.');
        }
      }
    };
    if (axisDict[key].hasOwnProperty('min')) {
      scales[key].min = axisDict[key].min;
    }
    if (axisDict[key].hasOwnProperty('max')) {
      scales[key].max = axisDict[key].max;
    }
  });
  return scales;
}

const backgroundRangeColor = {
  id: 'backgroundRangeColor',
  beforeDatasetsDraw: (chart: any, args: any, options: any) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right, height, width }
    } = chart;
    const { ranges } = options;

    if (chart.data.datasets.length > 0) {
      ranges.forEach((range: any) => {
        const to = range.to || 0;
        const from = range.from || 0;
        const color = range.color || 'rgba(0, 0, 0, 0.1)';
        const datasetIndex = range.datasetIndex || 0;
        if (datasetIndex < chart.data.datasets.length) {
          const dataset = chart.data.datasets[datasetIndex];
          const yScale = chart.scales[dataset.yAxisID];
          const xScale = chart.scales.x;
          if (yScale && xScale) {
            const yMin = yScale.getPixelForValue(yScale.min);
            const yMax = yScale.getPixelForValue(yScale.max);
            const xMin = xScale.getPixelForValue(from);
            const xMax = xScale.getPixelForValue(to);
            ctx.save();
            ctx.fillStyle = color;
            ctx.fillRect(xMin, yMin, xMax - xMin, yMax - yMin);
            ctx.restore();
          }
        }
      });
    }
  }
};

function LineGraphSimple({
  _data, // Data for graph
  title, // Title of graph
  showTooltip, // Show / hide tooltip
  onZoom, // Zoom event
  backgroundRanges = [], // Background ranges
  axisDict // Config for y axis
}: ILineGraphSimple) {
  console.log('LineGraphSimple');
  console.log('Data', _data);

  const { setIsLiveMode, chart, syncZoom, zoomState } =
    useContext(ChartContext);

  function startFetch({ chart }: { chart: ChartJS }) {
    const { min, max } = chart.scales.x;
    if (!(min == 0 && max == _data.x.length - 1)) {
      onZoom(min, max);
      setIsLiveMode(false);
      chart.resetZoom('none');
    }
  }

  // Options
  // const options: any = {
  //   responsive: true,
  //   animation: {
  //     duration: 0
  //   },
  //   interaction: {
  //     intersect: false,
  //     axis: 'xy'
  //   },
  //   elements: {
  //     point: {
  //       radius: 0
  //     }
  //   },
  //   scales: {
  //     // ...generateScales(axisDict),
  //     x: {
  //       ticks: {
  //         maxTicksLimit: 15,
  //         display: _data.x_config.showAxis
  //       },
  //       min: zoomState.x.min,
  //       max: zoomState.x.max
  //     },
  //     y: {
  //       ticks: {
  //         callback: (value: any) => {
  //           // console.log('XXXXXXX', value);
  //           if (value != 'original') {
  //             return formatNumberToFiveDigits(value.toFixed(2));
  //           } else {
  //             return value;
  //           }
  //           // return value;
  //         }
  //         //value && formatNumberToFiveDigits(value.toFixed(2))
  //       },
  //       min: _data.scales.y?.min,
  //       max: _data.scales.y?.max
  //     }
  //   },
  //   plugins: {
  //     backgroundRangeColor: {
  //       ranges: backgroundRanges
  //     },
  //     zoom: {
  //       limits: {
  //         x: {
  //           min: 0,
  //           max: 'original'
  //         },
  //         y: {
  //           min: 0,
  //           max: 100
  //         }
  //       },
  //       // pan: {
  //       //   enabled: true,
  //       //   mode: 'x',
  //       //   onPan: ({ chart }) => {
  //       //     syncZoom(chart);
  //       //   }
  //       // },
  //       zoom: {
  //         drag: {
  //           enabled: true,
  //         },
  //         // wheel: {
  //         //   enabled: true
  //         // },
  //         pinch: {
  //           enabled: true
  //         },
  //         mode: 'x',
  //         // onZoom: ({ chart }) => {
  //         //   syncZoom(chart);
  //         // }
  //       }
  //     },
  //     legend: {
  //       display: false,
  //       position: 'bottom' as const
  //     },
  //     title: {
  //       display: true,
  //       text: title,
  //       // LINK https://www.chartjs.org/docs/latest/configuration/title.html
  //       fullSize: true, // If false, the box is sized and placed above/beside the chart area.
  //       position: 'left',
  //       // color: 'blue',
  //       padding: 0,
  //       font: {
  //         size: 18,
  //         weight: 'bold'
  //       }
  //     },
  //     maintainAspectRatio: false,
  //     tooltip: {
  //       enabled: showTooltip,
  //       callbacks: {
  //         title: (tooltipItem: any) => {
  //           return `${_data.x[tooltipItem[0].dataIndex]}`;
  //         },
  //         label: (tooltipItem: any) => {
  //           return `${tooltipItem.dataset.label}: ${tooltipItem.dataset.data[
  //             tooltipItem.dataIndex
  //           ].toFixed(2)}`;
  //         }
  //       }
  //     }
  //   }
  // };

  const options: any = {
    responsive: true,
    animation: {
      duration: 0
    },
    interaction: {
      intersect: false,
      axis: 'x'
    },
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      ...generateScales(axisDict),
      x: {
        ticks: {
          maxTicksLimit: 15,
          display: _data.x_config.showAxis
        }
      }
    },
    plugins: {
      backgroundRangeColor: {
        ranges: backgroundRanges
      },
      zoom: {
        limits: {
          x: {
            min: 0,
            max: 'original'
          }
        },
        pan: {
          enabled: true,
          mode: 'x'
        },
        zoom: {
          drag: {
            enabled: true
          },
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        }
      },
      legend: {
        display: false,
        position: 'bottom' as const
      },
      title: {
        display: true,
        text: title,
        // LINK https://www.chartjs.org/docs/latest/configuration/title.html
        fullSize: false, // If false, the box is sized and placed above/beside the chart area.
        position: 'left',
        // color: 'blue',
        padding: 0,
        font: {
          size: 18,
          weight: 'bold'
        }
      },
      maintainAspectRatio: false,
      tooltip: {
        enabled: showTooltip,
        // enabled: false,
        // position: 'nearest',
        // external: function (context: any) {
        //   // Tooltip Element
        //   const { chart, tooltip } = context;
        //   const tooltipEl: any = getOrCreateTooltip(chart);

        //   // tooltipEl.innerHTML = '<p>Tes dsadsad asd asd asdsa dsads</p>';

        //   // Hide if no tooltip
        //   if (tooltip.opacity === 0) {
        //     tooltipEl.style.opacity = 0;
        //     return;
        //   }

        //   let predominateColor = '#000000';
        //   let title = (tooltip.title || [''])[0] || '';
        //   const htmlList: string[] = [];
        //   if (tooltip.dataPoints) {
        //     // colors
        //     tooltip.dataPoints.forEach(function (
        //       dataPoint: any,
        //       index: number
        //     ) {
        //       const { dataset, datasetIndex, dataIndex } = dataPoint;
        //       const typePoint = data.y_type_point[datasetIndex][dataIndex];
        //       const yValue = dataset.data[dataPoint.dataIndex];
        //       const color = dataset.pointBackgroundColor[dataPoint.dataIndex];
        //       console.log(color);
        //       console.log(data);
        //       console.log(typePoint);
        //       console.log(yValue);
        //       if (typePoint === 'Error') {
        //         predominateColor = 'rgba(255, 0, 0, 1)';
        //       } else if (typePoint === 'Warning') {
        //         predominateColor = 'rgba(255, 255, 0, 1)';
        //       } else {
        //         predominateColor = 'rgba(0, 0, 0, 1)';
        //       }
        //     });
        //     // const html = `<div style="background: ${}">
        //     // </div>`;
        //     // const titleLines = tooltip.title || [];
        //     // const bodyLines = tooltip.body.map((b: any) => b.lines);
        //     // console.log('tooltip----------------');
        //     // // dataIndex

        //     // console.log(tooltip);
        //     // console.log(tooltip.labelColors);
        //     // console.log(tooltip.dataPoints);
        //     // console.log(titleLines);
        //     // console.log(bodyLines);
        //   }

        //   tooltipEl.innerHTML = `<div style="background: ${predominateColor};">
        //     <strong>${title}</strong>
        //     <div>
        //       ${htmlList.join('</br>')}
        //     </div>
        //   </div>`;

        //   const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        //   // Display, position, and set styles for font
        //   tooltipEl.style.opacity = 1;
        //   tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        //   tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        //   tooltipEl.style.font = tooltip.options.bodyFont.string;
        //   tooltipEl.style.padding =
        //     tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        // }
        callbacks: {
          title: (tooltipItem: any) => {
            return `${_data.x[tooltipItem[0].dataIndex]}`;
          },
          label: (tooltipItem: any) => {
            // return '';
            return `${tooltipItem.dataset.label}: ${tooltipItem.dataset.data[
              tooltipItem.dataIndex
            ].toFixed(2)}`;
          }
        }
      }
    }
  };

  // new_x = process_x(data.x, 10);
  // new_labels = process_labels(data.x_labels);
  // new_y = process_y(data.y);
  //
  //process_y(data.y);

  const source = {
    labels: _data.x_labels,
    datasets: _data.y_config.map(
      (yConfig: DataGraphYConfig, index: number): any => {
        const pointStyle: string[] = [];
        const pointColor: string[] = [];
        const pointRadius: number[] = [];
        if (_data.y && _data.y.length > 0 && _data.y[index]) {
          // _data.y[index].map((_: any, _index: number) => {
          //   const type = _data.y_type_point[index][_index];
          //   if (type && type === 'Error') {
          //     pointStyle.push('circle');
          //     pointColor.push('rgba(255, 0, 0, 1)');
          //     pointRadius.push(2);
          //   } else if (type && type === 'Warning') {
          //     pointStyle.push('circle');
          //     pointColor.push('rgba(255, 255, 0, 1)');
          //     pointRadius.push(2);
          //   } else {
          //     pointStyle.push('circle');
          //     pointColor.push(yConfig.color);
          //     pointRadius.push(0);
          //   }
          // });
        }
        return {
          label: yConfig.label,
          yAxisID: yConfig.groupAxis,
          data: _data.y[index],
          borderWidth: 1,
          borderColor: yConfig.color,
          backgroundColor: yConfig.color,
          pointStyle,
          pointBackgroundColor: pointColor,
          pointBorderColor: pointColor,
          pointRadius,
          pointHoverRadius: [4, 0],
          hidden: !yConfig.isShow
        };
      }
    )
  };

  return (
    <Fragment>
      <div className="max-w-full max-h-full h-full px-[30px]">
        <Line
          ref={chart}
          className="w-full h-full max-w-full max-h-full"
          options={options}
          // plugins={[backgroundRangeColor]}
          data={source}
        />
      </div>
    </Fragment>
  );
}

export default memo(LineGraphSimple);
