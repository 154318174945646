import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  NAVBAR_MODE,
  NAVEGATION_STATE,
  TYPE_SPACE,
  USER_SETTINGS
} from '../../config/enum';
import Space from '../Space';
import NavigationTop from './NavigationTop';
import NavigationSide from './NavigationSide';
import './style.css';
import NavigationActivator from './NavigationActivator';
import NavigationSideCollapsed from './NavigationSideCollapsed';
import { NavContext } from '../../context/NavContext';

export interface IDashboardPageProps {
  mode: NAVBAR_MODE;
  children: any;
}

function Navegation({ children, mode }: IDashboardPageProps) {
  // SECTION States
  const { stateNavegation, setStateNavegation, collapsed } =
    useContext(NavContext);
  const [isOpen, setIsOpen] = useState(USER_SETTINGS.CLOSE);
  // !SECTION

  // console.log({ collapsed, stateNavegation, mode });
  //SECTION Render
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  // !SECTION

  // Open and close navegation
  function showNavegationSide(activate: boolean) {
    // activate
    //   ? setStateNavegation(NAVEGATION_STATE.OPEN)
    //   : setStateNavegation(NAVEGATION_STATE.CLOSE);
    console.log(stateNavegation);
    setStateNavegation(NAVEGATION_STATE.OPEN);
  }

  function closeNavegationSide() {
    setStateNavegation(NAVEGATION_STATE.COMPRESSED);
  }

  function handleOpen() {
    console.log('handleOpen');
    closeNavegationSide();
    // isOpen == USER_SETTINGS.OPEN
    //   ? setIsOpen(USER_SETTINGS.CLOSE)
    //   : setIsOpen(USER_SETTINGS.OPEN);
  }

  useEffect(() => {
    const el: any = ref.current;
    setHeight(el.clientHeight);
  });

  useEffect(() => {
    if (mode != NAVBAR_MODE.FIXED) {
      setStateNavegation(NAVEGATION_STATE.COMPRESSED);
    }
  }, [mode]);

  // SECTION Calculate classes
  const overContainerClasses =
    mode == NAVBAR_MODE.OVER ? 'content-page-over' : 'content-page-over';
  const overSideBarClasses =
    mode == NAVBAR_MODE.OVER
      ? NAVEGATION_STATE.COMPRESSED == stateNavegation
        ? 'lg:fixed w-[80px]'
        : 'lg:fixed lg:w-[350px]'
      : '';

  const fixedContainerClasses =
    mode == NAVBAR_MODE.FIXED ? 'content-page-fixed' : '';
  const fixedSideBarClasses =
    mode == NAVBAR_MODE.FIXED ? 'lg:relative lg:w-[450px]' : '';

  const collapsedContainerClasses =
    mode == NAVBAR_MODE.COLLAPSED
      ? NAVEGATION_STATE.COMPRESSED == stateNavegation
        ? 'content-page-collapsed-close'
        : 'content-page-collapsed-open'
      : '';

  const collapsedSideBarClasses =
    mode == NAVBAR_MODE.COLLAPSED
      ? NAVEGATION_STATE.COMPRESSED == stateNavegation
        ? 'lg:relative lg:w-[80px]'
        : 'lg:relative lg:w-[350px]'
      : '';

  // const sideNavRef = useRef<HTMLDivElement>(null);

  // const handleClickOutside = useCallback(
  //   (e: MouseEvent) => {
  //     if (!collapsed)
  //       setStateNavegation(
  //         sideNavRef && !sideNavRef.current?.contains(e.target as Node)
  //           ? NAVEGATION_STATE.COMPRESSED
  //           : NAVEGATION_STATE.OPEN
  //       );
  //   },
  //   [collapsed]
  // );

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => document.removeEventListener('mousedown', handleClickOutside);
  // }, [handleClickOutside]);

  // !SECTION
  // console.log('---------------------------------------------------');
  // console.log('overContainerClasses       ', overContainerClasses);
  // console.log('fixedContainerClasses      ', fixedContainerClasses);
  // console.log('collapsedContainerClasses  ', collapsedContainerClasses);
  // console.log('overSideBarClasses         ', overSideBarClasses);
  // console.log('fixedSideBarClasses        ', fixedSideBarClasses);
  // console.log('collapsedSideBarClasses    ', collapsedSideBarClasses);
  return (
    <Fragment>
      <div className="flex relative w-full h-full">
        {/* SECTION Navigation Side  */}
        <div
          className={`h-full fixed ease-linear duration-100 z-10 top-0 left-0 ${
            overSideBarClasses || fixedSideBarClasses || collapsedSideBarClasses
          } ${
            NAVEGATION_STATE.CLOSE == stateNavegation
              ? 'close-navigation-container'
              : 'open-navigation-container'
          }`}>
          <div
            className={
              'h-full lg:relative absolute top-0 left-0 bg-color-white'
            }>
            <NavigationSide
              collapsed={collapsed && mode != NAVBAR_MODE.FIXED}
              // enableCollapsed={mode != NAVBAR_MODE.FIXED}
              enableCollapsed={mode != NAVBAR_MODE.FIXED}
              setStateNavigation={setStateNavegation}
              onCollapsed={(collapsed: boolean) => {
                setStateNavegation(
                  collapsed
                    ? NAVEGATION_STATE.COMPRESSED
                    : NAVEGATION_STATE.OPEN
                );
              }}
            />
          </div>
        </div>
        {/* !SECTION */}
        {/* SECTION Content Page */}
        <div
          onClick={handleOpen}
          className={`w-full h-full flex flex-col overflow-y-auto ${
            overContainerClasses ||
            fixedContainerClasses ||
            collapsedContainerClasses
          }`}>
          {/* SECTION Navigation */}
          <div className="w-full" ref={ref}>
            {/* SECTION Side Navigation Activator */}
            <div className="w-full lg:hidden">
              <Space type={TYPE_SPACE.TEXT_DISTANCE} classes="w-full" />
              <NavigationActivator onActive={() => showNavegationSide(true)} />
              <Space type={TYPE_SPACE.TEXT_DISTANCE} classes="w-full" />
            </div>
            {/* !SECTION */}
            {/* SECTION Navigation Top */}
            <div className="w-full">
              <NavigationTop />
            </div>
            {/* !SECTION */}
          </div>
          {/* !SECTION */}
          {/* SECTION Content Page */}
          <div
            className="w-full"
            style={{ height: `calc(100% - ${height}px)` }}>
            {children}
          </div>
          {/* !SECTION */}
        </div>
        {/* !SECTION */}
      </div>
    </Fragment>
  );
}

export default Navegation;
