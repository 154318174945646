import { createContext, useMemo, useRef, useState } from 'react';
import { NAVEGATION_STATE } from '../config/enum';

type NavContextProps = {
  children: React.ReactNode;
};

export const NavContext = createContext<any>({});

export default ({ children }: NavContextProps) => {
  const [stateNavegation, setStateNavegation] = useState(NAVEGATION_STATE.OPEN);
  const collapsed: boolean = useMemo(
    () => stateNavegation == NAVEGATION_STATE.COMPRESSED,
    [stateNavegation]
  );
  const sideNavRef = useRef<HTMLDivElement>(null);

  return (
    <NavContext.Provider
      value={{
        stateNavegation,
        setStateNavegation,
        collapsed,
        sideNavRef
      }}>
      {children}
    </NavContext.Provider>
  );
};
