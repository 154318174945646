import './index.css';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore
} from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import { electripureReducer } from './reducers/electipure';
import ReactDOM from 'react-dom/client';
import AppRouter from './routers/AppRouter';
import { FirebaseAppProvider } from 'reactfire';
import environment from './config/env';
import AuthContext from './context/AuthContext';
import React from 'react';
import NavContext from './context/NavContext';
import GraphContext from './context/GraphContext';

const composeAlt: any =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancer: any = composeAlt(applyMiddleware(thunk));

const store = createStore(electripureReducer, composeEnhancer);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    {/* <FirebaseAppProvider firebaseConfig={environment.FIREBASE_CONFIG}>

    </FirebaseAppProvider> */}
          <NavContext>
        <AuthContext>
          <GraphContext>
            <AppRouter />
          </GraphContext>
        </AuthContext>
      </NavContext>
  </Provider>
);
