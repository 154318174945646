export const LOGIN = 'LOGIN';
export const SET_LOADING = 'SET_LOADING';
export const SET_JWT = 'SET_JWT';
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN';
export const SET_PASSWORD_TOKEN = 'SET_PASSWORD_TOKEN';
export const SET_PASSWORD_USER = 'SET_PASSWORD_USER';
export const SET_TIMESTAMP_TWO_STEP_VERIFICATION =
  'SET_TIMESTAMP_TWO_STEP_VERIFICATION';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SET_USERS = 'SET_USERS';
export const SET_COMPANIES_TABLE = 'SET_COMPANIES_TABLE';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES';
export const SET_GLOBAL_COMPANIES = 'SET_GLOBAL_COMPANIES';
export const ADD_TASK = 'SET_TASK';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AMPS_DATA = 'SET_AMPS_DATA';
export const SET_VOLTS_DATA = 'SET_VOLTS_DATA';
export const FILTER_AMPS_DATA = 'FILTER_AMPS_DATA';
export const FILTER_VOLTS_DATA = 'FILTER_VOLTS_DATA';
export const SET_COMPANY_DETAIL = 'SET_COMPANY_DETAIL';
export const SET_DEVICES_TABLE = 'SET_DEVICES_TABLE';
export const GENERATE_FCMTOKEN = 'GENERATE_FCMTOKEN';
export const SET_FCMTOKEN = 'SET_FCMTOKEN';
export const SET_REMEMBERTOKEN = 'SET_REMEMBERTOKEN';
export const RECOVERY_FCMTOKEN = 'RECOVERY_REMEMBERTOKEN';
export const SAVE_REMEMBERTOKEN = 'SAVE_REMEMBERTOKEN';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'GET_NOTIFICATION';
