import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SendImage,
  sendActivateDeactivateCompany
} from '../../../actions/electripure';
import {
  INPUT_CONTROL_STATE,
  TASK_STATE,
  TYPE_SPACE
} from '../../../config/enum';
import enviroments from '../../../config/env';
const { STATES } = enviroments;
import { CurrentUser, TaskEntity } from '../../../interfaces/entities';
import { InputControl } from '../../../interfaces/form-control';
import { ElectripureState } from '../../../interfaces/reducers';
import {
  validateCellphoneControl,
  validateCompanyControl,
  validateEmailControl,
  validateNameControl,
  validateRequiredControl
} from '../../../libs/form-validation';
import { ButtonPrimary } from '../../FormInput/Button';
import InputPhoto from '../../FormInput/InputPhoto';
import InputSelect from '../../FormInput/InputSelect';
import InputText from '../../FormInput/InputText';
import Title from '../../FormInput/Title';
import Space from '../../Space';
import formatter from '../../../libs/formatter';
import ToogleSwitch from '../../FormInput/toogleSwitch';

export interface ICompanyUpdateForm {
  id: number;
  address: string;
  address2: string;
  url_image: string;
  id_image: string;
  contact_fullname: string;
  contact_email: string;
  contact_phone: string;
  city: string;
  name: string;
  state: string;
  active: boolean;
  zip: string;
}

function CompanyUpdateForm({
  company,
  onSubmit,
  setIsActive,
}: {
  company: ICompanyUpdateForm;
  onSubmit: (data: Partial<ICompanyUpdateForm>) => void;
  setIsActive: (value: boolean) => void;
}) {
  // const {ciaId} = useParams();
  const dispatch = useDispatch();

  const [cellphone, setCellphone] = useState(company.contact_phone ?? '');

  const uploadLogoTask: TaskEntity =
    JSON.parse(useSelector((state: ElectripureState) => state.tasks))[
      'UPLOAD_LOGO'
    ] ?? {};

  const [contactFullNameControl, setContactFullNameControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.contact_fullname ?? '',
    message: ''
  });
  const [contactEmailControl, setContactEmailControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.contact_email ?? '',
    message: ''
  });
  const [contactPhoneControl, setContactPhoneControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.contact_phone ?? '',
    message: ''
  });
  const [companyControl, setCompanyControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.name ?? '',
    message: ''
  });

  const [addressControl, setAddressControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.address ?? '',
    message: ''
  });

  const [address2Control, setAddress2Control] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.address2 ?? '',
    message: ''
  });

  const [cityControl, setCityControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.city ?? '',
    message: ''
  });
  const [stateControl, setStateControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company.state ?? '',
    message: ''
  });

  const [zipControl, setZipControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company?.zip ?? '',
    message: ''
  });

  const [logoControl, setLogoControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: company.id_image,
    message: ''
  });

  const [logoValid, setLogoValid] = useState(false);
  const [urlImage, setUrlImage] = useState(company.url_image);

  function uploadLogo({ base64, size }: { base64: string; size: number }) {
    if (size > 500000) {
      setLogoValid(false);
      setLogoControl({
        message: 'Image max size is 500kb.',
        state: INPUT_CONTROL_STATE.ERROR,
        value: ''
      });
      return;
    }
    setLogoValid(true);
    setUrlImage(base64);
    dispatch(
      SendImage({
        base64: base64.split(',')[1],
        taskKey: 'UPLOAD_LOGO'
      })
    );
  }

  function submit() {
    if (
      companyControl.state == INPUT_CONTROL_STATE.OK &&
      addressControl.state == INPUT_CONTROL_STATE.OK &&
      cityControl.state == INPUT_CONTROL_STATE.OK &&
      stateControl.state == INPUT_CONTROL_STATE.OK &&
      contactEmailControl.state == INPUT_CONTROL_STATE.OK &&
      contactFullNameControl.state == INPUT_CONTROL_STATE.OK &&
      contactPhoneControl.state == INPUT_CONTROL_STATE.OK &&
      zipControl.state == INPUT_CONTROL_STATE.OK
    ) {
      onSubmit({
        name: companyControl.value,
        address: addressControl.value,
        address2: address2Control.value,
        city: cityControl.value,
        state: stateControl.value,
        zip: zipControl.value,
        id: company.id,
        id_image: logoControl.value,
        url_image: urlImage,
        contact_email: contactEmailControl.value,
        contact_fullname: contactFullNameControl.value,
        contact_phone: contactPhoneControl.value
      });
    } else {
      setCompanyControl(validateRequiredControl(companyControl));
      setAddressControl(validateRequiredControl(addressControl));
      setCityControl(validateRequiredControl(cityControl));
      setStateControl(validateRequiredControl(stateControl));
      setContactEmailControl(validateRequiredControl(contactEmailControl));
      setContactFullNameControl(
        validateRequiredControl(contactFullNameControl)
      );
      setContactPhoneControl(validateRequiredControl(contactPhoneControl));
      setZipControl(validateRequiredControl(zipControl));
    }
  }

  function deactivateCompany() {
    dispatch(
      sendActivateDeactivateCompany({
        id: company.id,
        action: 'deactivate'
      })
    );
  }
  function activateCompany() {
    dispatch(
      sendActivateDeactivateCompany({
        id: company.id,
        action: 'activate'
      })
    );
  }

  const handleChangeCompanyStatus = () => {
    if (company.active) {
      deactivateCompany();
    } else {
      activateCompany();
    }
    setIsActive(!company.active);
  };
  useEffect(() => {
    if (uploadLogoTask.state == TASK_STATE.COMPLETED && logoValid) {
      setLogoControl({
        ...logoControl,
        message: '',
        state: INPUT_CONTROL_STATE.OK,
        value: uploadLogoTask.result
      });
    }
  }, [uploadLogoTask.state]);

  return (
    <div className="w-full bg-color-white p-[10px]">
      <Space type={TYPE_SPACE.INPUT_DISTANCE} />
      <div
        className="mx-auto w-full max-w-[650px]"
        style={{ textAlign: 'center' }}>
        <Title title="Update Company information" />
      </div>
      <div className="w-full flex">
        <div className="w-[200px] p-[5px] h-[200px]">
          <InputPhoto
            name="companyLogo"
            src={urlImage}
            placeholder="Add company logo"
            onChange={uploadLogo}
            state={logoControl.state}
            message={logoControl.message}
          />
          <ToogleSwitch
            labelOn="Active"
            labelOff="Not Active"
            isOn={company.active}
            widthOff={130}
            widthOn={100}
            onClick={handleChangeCompanyStatus}
          />
        </div>
        <div className="w-full pl-[20px]">
          <InputText
            name="company"
            label="Company Name"
            placeholder="Company name"
            state={companyControl.state}
            message={companyControl.message}
            defaultValue={company.name}
            onChange={(value: string) => {
              const newCompanyControl: InputControl =
                validateCompanyControl(value);
              setCompanyControl(newCompanyControl);
            }}
          />
          <Space type={TYPE_SPACE.INPUT_DISTANCE} />
          <InputText
            name="address"
            label="Address"
            placeholder="12345 Street Address"
            state={addressControl.state}
            message={addressControl.message}
            defaultValue={company.address}
            onChange={(value: string) => {
              setAddressControl({
                state:
                  value == ''
                    ? INPUT_CONTROL_STATE.DEFAULT
                    : INPUT_CONTROL_STATE.OK,
                message: '',
                value: value
              });
            }}
          />
          <Space type={TYPE_SPACE.INPUT_DISTANCE} />
          <InputText
            name="address2"
            label="Address 2 optional"
            placeholder="Suite 890"
            state={address2Control.state}
            message={address2Control.message}
            defaultValue={company.address2}
            onChange={(value: string) => {
              setAddress2Control({
                state:
                  value == ''
                    ? INPUT_CONTROL_STATE.DEFAULT
                    : INPUT_CONTROL_STATE.OK,
                message: '',
                value: value
              });
            }}
          />
          <Space type={TYPE_SPACE.INPUT_DISTANCE} />
          <div className="flex">
            <InputText
              name="city"
              label="City"
              placeholder="City"
              state={cityControl.state}
              message={cityControl.message}
              defaultValue={company.city}
              onChange={(value: string) => {
                setCityControl({
                  state:
                    value == ''
                      ? INPUT_CONTROL_STATE.DEFAULT
                      : INPUT_CONTROL_STATE.OK,
                  message: '',
                  value: value
                });
              }}
            />
            <Space classes="w-[60px]" />
            <InputSelect
              name="state"
              label="State"
              options={STATES.map((value, index) => ({
                id: index + 1,
                value: value
              }))}
              placeholder="Select State"
              state={stateControl.state}
              defaultSelect={stateControl.value}
              message={stateControl.message}
              onChange={(select: { value: any; id: any }) => {
                setStateControl({
                  state: INPUT_CONTROL_STATE.OK,
                  message: '',
                  value: select.value
                });
              }}
            />
            <Space classes="w-[60px]" />
            <InputText
              name="zip"
              label="Zipcode"
              placeholder="Zip"
              state={zipControl.state}
              message={zipControl.message}
              defaultValue={company.zip}
              onChange={(value: string) => {
                setZipControl({
                  state:
                    value == ''
                      ? INPUT_CONTROL_STATE.DEFAULT
                      : INPUT_CONTROL_STATE.OK,
                  message: '',
                  value: value
                });
              }}
            />
          </div>

          <Space type={TYPE_SPACE.INPUT_DISTANCE} />
          <InputText
            name="fullname"
            label="Contact Full Name"
            placeholder="John Doe"
            value={contactFullNameControl.value}
            state={contactFullNameControl.state}
            message={contactFullNameControl.message}
            onChange={(value: string) => {
              const newContactFullNameControl: InputControl =
                validateNameControl(value);
              setContactFullNameControl(newContactFullNameControl);
            }}
          />
          <Space type={TYPE_SPACE.INPUT_DISTANCE} />
          <InputText
            name="email"
            label="Contact Email"
            placeholder="email@company.com"
            value={contactEmailControl.value}
            state={contactEmailControl.state}
            message={contactEmailControl.message}
            onChange={(value: string) => {
              const newContactEmailControl: InputControl =
                validateEmailControl(value);
              setContactEmailControl(newContactEmailControl);
            }}
          />
          <Space type={TYPE_SPACE.INPUT_DISTANCE} />
          <InputText
            name="cellphone"
            label="Contact Cellphone"
            value={cellphone}
            placeholder="(***) *** - ****"
            state={contactPhoneControl.state}
            message={contactPhoneControl.message}
            onChange={(value: string) => {
              const cellphone = value
                .replace('-', '')
                .replace('(', '')
                .replace(')', '')
                .replace(' ', '');

              const newContactPhoneControl: InputControl =
                validateCellphoneControl(cellphone);
              setContactPhoneControl(newContactPhoneControl);

              if (newContactPhoneControl.state == INPUT_CONTROL_STATE.OK) {
                setCellphone(formatter.toCellphoneFormat(cellphone));
              } else {
                setCellphone(value);
              }

              setContactPhoneControl(newContactPhoneControl);
            }}
          />
        </div>
      </div>
      <Space classes="w-full h-[50px]" />
      <div className="w-[160px] mx-auto">
        <ButtonPrimary onClick={submit}>Update</ButtonPrimary>
      </div>
    </div>
  );
}

export default CompanyUpdateForm;
