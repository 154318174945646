import { useEffect, useRef, useState } from 'react';

export interface IPointSelected {
  x: number;
  y: number;
}

interface IPointConfig {
  src: any;
  width: number;
  height: number;
}

function SelectPointInput({
  defaultPointSelected,
  img,
  pointConfig,
  onChange
}: {
  defaultPointSelected?: IPointSelected;
  img: any;
  pointConfig: IPointConfig;
  onChange: (point: IPointSelected) => void;
}) {
  // SECTION Create states
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(-1);
  const [left, setLeft] = useState(-1);
  // !SECTION
  // SECTION Create refs
  const ref = useRef(null);
  // !SECTION
  // SECTION Get height and width relative of the image and calculate initial position
  useEffect(() => {}, []);
  function calculareWidthAndHeight() {
    const elem: any = ref!.current;
    setHeight(elem.clientHeight);
    setWidth(elem.clientWidth);
    if (
      defaultPointSelected &&
      defaultPointSelected.x > -1 &&
      defaultPointSelected.y > -1
    ) {
      // Generate porcent to pixel
      setTop((elem.clientHeight / 100) * defaultPointSelected.y);
      setLeft((elem.clientWidth / 100) * defaultPointSelected.x);
    }
  }
  // !SECTION
  // SECTION Handle mouse down
  function onMouseDown(e: any) {
    if (e.target.tagName == 'IMG') {
      return;
    }
    let top =
      e.clientY - (e.target.getBoundingClientRect().top + window.scrollY);
    let left =
      e.clientX - (e.target.getBoundingClientRect().left + window.scrollX);
    setTop(top);
    setLeft(left);
    onChange({
      x: (left / width) * 100,
      y: (top / height) * 100
    });
  }
  // !SECTION
  return (
    <div>
      <div className="w-full relative">
        <img
          src={img}
          ref={ref}
          className="w-full"
          onLoad={calculareWidthAndHeight}
        />
        <div
          onMouseDown={onMouseDown}
          className="w-full h-full absolute top-0 left-0">
          {top == -1 || left == -1 ? (
            ''
          ) : (
            <img
              src={pointConfig.src}
              className="absolute"
              style={{
                top: `${top - pointConfig.height / 2}px`,
                left: `${left - pointConfig.width / 2}px`,
                width: `${pointConfig.width}px`,
                height: `${pointConfig.height}px`
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectPointInput;
