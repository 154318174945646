import { IMdp } from './interfaces';

const MDPDetail = ({ mdp }: { mdp: IMdp }) => {
  return (
    <div className="w-full p-2">
      <div className="mb-1 flex">
        <strong className="mr-1">Meter ID:</strong>
        <p className="text-[#00AEE8]"> {mdp.meterID}</p>
      </div>
      <div className="mb-1 flex">
        <strong className="mr-1">Appliance ID:</strong>
        <p className="text-[#00AEE8]"> {mdp.applianceID}</p>
      </div>
      <div className="mb-1">
        <strong className="mr-1">MDP rating:</strong> {mdp.MDP}
      </div>
      <div className="mb-1">
        <strong className="mr-1">Switchgear rating:</strong> {mdp.switchgear}
      </div>
      <div className="mb-1">
        <strong className="mr-1">Transformer rating:</strong> {mdp.transformer}
      </div>
    </div>
  );
};

export default MDPDetail;
