import { ILocation } from '../interfaces';

function LocationInfo({ location }: { location: ILocation }) {
  const companyMapsLink = `https://www.google.com/maps/place/${location.address}+${location.city}+${location.state}+${location.zip}`
  return (
    <div className="flex justify-start items-start w-auto">
      <div className="">
        <svg
          width="20"
          height="20"
          viewBox="0 0 11 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.59375 14.4219C3.17188 12.6445 0 8.40625 0 6C0 3.10156 2.32422 0.75 5.25 0.75C8.14844 0.75 10.5 3.10156 10.5 6C10.5 8.40625 7.30078 12.6445 5.87891 14.4219C5.55078 14.832 4.92188 14.832 4.59375 14.4219ZM5.25 7.75C6.20703 7.75 7 6.98438 7 6C7 5.04297 6.20703 4.25 5.25 4.25C4.26562 4.25 3.5 5.04297 3.5 6C3.5 6.98438 4.26562 7.75 5.25 7.75Z"
            fill="#737373"
          />
        </svg>
      </div>
      <div className="ml-[5px]">
        <a href={companyMapsLink} target='_blank' className="text-blue-electripure">{location.address}</a>
        <p>{location.address2}</p>
        <p>
          {location.city}, {location.state} {location.zip}
        </p>
      </div>
    </div>
  );
}

export default LocationInfo;
