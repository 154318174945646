import { IContact } from '../interfaces';

function ContactInfo({ contact }: { contact: IContact }) {
  return (
    <div className="flex justify-start items-start w-auto">
      <div className="">
        <svg
          width="20"
          height="20"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 0.625H1.75C0.765625 0.625 0 1.41797 0 2.375V11.125C0 12.1094 0.765625 12.875 1.75 12.875H14C14.957 12.875 15.75 12.1094 15.75 11.125V2.375C15.75 1.41797 14.957 0.625 14 0.625ZM4.8125 3.25C5.76953 3.25 6.5625 4.04297 6.5625 5C6.5625 5.98438 5.76953 6.75 4.8125 6.75C3.82812 6.75 3.0625 5.98438 3.0625 5C3.0625 4.04297 3.82812 3.25 4.8125 3.25ZM7.4375 10.25H2.1875C1.94141 10.25 1.75 10.0586 1.75 9.8125C1.75 8.60938 2.70703 7.625 3.9375 7.625H5.6875C6.89062 7.625 7.875 8.60938 7.875 9.8125C7.875 10.0586 7.65625 10.25 7.4375 10.25ZM13.5625 8.5H10.0625C9.81641 8.5 9.625 8.30859 9.625 8.0625C9.625 7.84375 9.81641 7.625 10.0625 7.625H13.5625C13.7812 7.625 14 7.84375 14 8.0625C14 8.30859 13.7812 8.5 13.5625 8.5ZM13.5625 6.75H10.0625C9.81641 6.75 9.625 6.55859 9.625 6.3125C9.625 6.09375 9.81641 5.875 10.0625 5.875H13.5625C13.7812 5.875 14 6.09375 14 6.3125C14 6.55859 13.7812 6.75 13.5625 6.75ZM13.5625 5H10.0625C9.81641 5 9.625 4.80859 9.625 4.5625C9.625 4.34375 9.81641 4.125 10.0625 4.125H13.5625C13.7812 4.125 14 4.34375 14 4.5625C14 4.80859 13.7812 5 13.5625 5Z"
            fill="#737373"
          />
        </svg>
      </div>
      <div className="ml-[5px]">
        <p>{contact.name}</p>
        <p>{contact.phone}</p>
        <p>{contact.email}</p>
      </div>
    </div>
  );
}

export default ContactInfo;
