import { useState } from 'react';
import { ICollapse } from './interfaces';

function Collapse({
  collapse,
  children
}: {
  collapse: ICollapse;
  children: any;
}) {
  const [toggleDetails, setToggleDetails] = useState(
    collapse.showDefault ?? false
  );
  return (
    <div className="m-[10px]">
      {/* SECTION Title */}
      <div className="flex justify-between items-center h-[50px]">
        <div className="flex items-center">
          <img src={collapse.src} alt="" />
          <p className="ml-[10px]">{collapse.title}</p>
        </div>
        <div
          onClick={() => setToggleDetails(!toggleDetails)}
          className="w-[50px] h-[50px] my-auto border-l-2 cursor-pointer">
          <i
            className={`
            inline-block
            border-solid
            border-black
            divide-x-[3px]
            p-[3px]
            mxlix-4
            ml-4
            mt-3
            inline-block
            border-b-2
            translate-x-1/2
            translate-y-1/2
            border-r-2 ${
              toggleDetails ? 'rotate-[-135deg]' : 'rotate-45'
            }`}></i>
        </div>
      </div>
      {/* !SECTION */}
      {/* SECTION Content */}
      <div
        style={{ transition: 'height 0.5s ease' }}
        className={`
        mt-1
        overflow-hidden
      ${toggleDetails ? 'h-auto' : 'h-[0px]'}
      `}>
        {children}
      </div>
      {/* !SECTION */}
    </div>
  );
}

export default Collapse;
