import { useContext } from 'react';
import { ChartContext } from '../../context/GraphContext';
import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AVERAGING_MODE } from '../../config/enum';

export default () => {
  const { averageMode, setAverageMode } = useContext(ChartContext);

  const averageModeOptions = [
    {
      label: 'None',
      value: AVERAGING_MODE.NONE
    },
    {
      label: '15 seconds',
      value: AVERAGING_MODE.FIFTEEN_SECONDS
    },
    {
      label: '30 seconds',
      value: AVERAGING_MODE.THIRTY_SECONDS
    },
    {
      label: '1 minute',
      value: AVERAGING_MODE.ONE_MINUTE
    },
    {
      label: '5 minutes',
      value: AVERAGING_MODE.FIVE_MINUTES
    },
    {
      label: '10 minutes',
      value: AVERAGING_MODE.TEN_MINUTES
    }
  ];

  const handleChangeAverageMode = ({ target }: SelectChangeEvent) => {
    setAverageMode(target.value as unknown as AVERAGING_MODE);
  };

  return (
    <div className="flex gap-x-4 items-center mt-4">
      <span>Average</span>
      <Select
        className="h-8 flex-1"
        value={averageMode.toString()}
        onChange={handleChangeAverageMode}>
        {averageModeOptions.map((option: any, index: number) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </div>
  );
};
