// Libraries
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Interfaces
import { ICompany } from './interfaces';
// Portal Components
import { ModalMiddle } from '../../components/Modal';
// Form Components
import CompanyUpdaeteForm, {
  ICompanyUpdateForm
} from '../../components/Form/CompanyUpdateForm';
// Components
import ToogleSwitch from '../../components/FormInput/toogleSwitch';
// Redux actions
import {
  sendActivateDeactivateCompany,
  sendArchiveCompany,
  sendUpdateCompany
} from '../../actions/electripure';
import CompanyUpdateForm from '../../components/Form/CompanyUpdateForm';
import LocationInfo from './components/LocationInfo';
import ContactInfo from './components/ContactInfo';
import Space from '../../components/Space';
import { TYPE_SPACE } from '../../config/enum';
import ArchivatePermission from '../../components/Permission/ArchivatePermission';
import { ElectripureState } from '../../interfaces/reducers';

function CompanyDetail() {
  const dispatch = useDispatch();
  const company: ICompany = JSON.parse(
    useSelector((state: ElectripureState) => state.companyDetails)
  );
  const currentUser = JSON.parse(
    useSelector((state: ElectripureState) => state.currentUser || '{}')
  );
  
  const [isActive, setIsActive] = useState<boolean>(company.status === '1');

  useEffect(() => setIsActive(company.status === '1'), [company]);

  const [showModalUpdateCompany, setShowModalUpdateCompany] = useState(false);
  // !SECTION
  // SECTION Define action functions
  function submitUpdateCompany(data: Partial<ICompanyUpdateForm>) {
    dispatch(
      sendUpdateCompany({
        company: data.name,
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        company_id: data.id,
        id_image: data.id_image,
        contact_fullname: data.contact_fullname,
        contact_email: data.contact_email,
        contact_phone: data.contact_phone,
        image: ''
      })
    );
    setShowModalUpdateCompany(false);
  }
  function archiveCompany() {
    dispatch(sendArchiveCompany({ id: company.id }));
  }

  // !SECTION

  return (
    <Fragment>
      <div className="flex justify-between items-start w-[100%]">
        {/* SECTION Company information */}
        <div className="w-[100%] flex">
          {/* SECTION Company image */}
          <div className="">
            <div className="rounded border flex flex-col w-[200px] h-[200px] p-[5px] mb-2">
              <img className="rounded w-[100%]" src={company.cia_image} />
            </div>
            <span
              className={`text-white px-3 py-1 rounded-md ${
                isActive ? 'bg-green-700' : 'bg-red-700'
              }`}>
              {isActive ? 'Active' : 'Not Active'}
            </span>
          </div>
          {/* !SECTION */}
          {/* SECTION Company info */}
          <div
            className="py-[10px] px-[20px]"
            style={{ width: 'calc(100% - 350px)' }}>
            {/* SECTION Company title */}
            <div className="flex items-end justify-start">
              <div className="text-2xl font-medium">
                <h1>{company.name}</h1>
              </div>
              {currentUser.roles?.edit_company === 1 && (
                <div className="w-[200px] pl-[10px]">
                  <span
                    className="cursor-pointer h-[40px] text-[#00AEE8]"
                    onClick={() => setShowModalUpdateCompany(true)}>
                    Edit Company
                  </span>
                </div>
              )}
            </div>
            {/* !SECTION */}
            {/* SECTION Company info */}
            <div>
              <Space type={TYPE_SPACE.TEXT_DISTANCE} />
              <LocationInfo
                location={{
                  address: company.address,
                  address2: company.address2,
                  city: company.city,
                  state: company.state,
                  zip: company.zip
                }}
              />
              <Space type={TYPE_SPACE.TEXT_DISTANCE} />
              <ContactInfo
                contact={{
                  phone: company.contact_phone,
                  email: company.contact_email,
                  name: company.contact_name
                }}
              />
            </div>
            {/* !SECTION */}
          </div>
          {/* !SECTION */}

          {/* SECTION Company actions */}
          <div className="w-[150px] flex flex-col justify-start items-center">
            <ArchivatePermission>
              <div
                className="cursor-pointer flex justify-center items-center mt-[10px]"
                onClick={archiveCompany}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                <span className="text-sm ml-[5px]">Archive</span>
              </div>
            </ArchivatePermission>
          </div>
          {/* !SECTION */}
        </div>
        {/* !SECTION */}
      </div>
      {/* SECTION PopUp */}
      <ModalMiddle
        show={showModalUpdateCompany}
        onClose={() => {
          setShowModalUpdateCompany(false);
        }}>
        <CompanyUpdateForm
          company={{
            ...company,
            id: company.id,
            url_image: company.cia_image,
            id_image: company.id_image,
            contact_fullname: company.contact_name,
            contact_email: company.contact_email,
            contact_phone: company.contact_phone,
            active: isActive
          }}
          onSubmit={submitUpdateCompany}
          setIsActive={setIsActive}
        />
      </ModalMiddle>
      {/* !SECTION */}
    </Fragment>
  );
}

export default CompanyDetail;
