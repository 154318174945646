// Librairies
import { Fragment, useState } from 'react';
// Enums
import { TYPE_SPACE } from '../../config/enum';
// Portal Components
import { ModalMiddle } from '../../components/Modal';
// Interfaces
import { ICompany, ISite } from './interfaces';
// Components
import Space from '../../components/Space';
import LocationInfo from './components/LocationInfo';
import ContactInfo from './components/ContactInfo';
import SiteUpdateForm, { ISiteForm } from '../../components/Form/SiteForm';
import { useDispatch } from 'react-redux';
import {
  deleteSite,
  sendGetCompanyDetail,
  sendUpdateSite
} from '../../actions/electripure';
import { toast } from 'react-toastify';

const SiteDetail = ({ site, company }: { site: ISite; company: ICompany }) => {
  const [showModalUpdatesite, setShowModalUpdatesite] = useState(false);
  const dispatch = useDispatch();

  const submitUpdateSite = (site: Partial<ISiteForm>) => {
    dispatch(
      sendUpdateSite({
        site_id: site.id,
        schematic_id: site.schematic_id,
        name: site.name,
        address: site.address,
        address2: site.address2,
        city: site.city,
        state: site.state,
        zip: site.zip,
        logo_id: site.logo_id,
        payment: site.payment,
        manager_email: site.manager_email,
        manager_fullname: site.manager_fullname,
        manager_phone: site.manager_phone,
        company_id: company.id
      })
    );
    setShowModalUpdatesite(false);
  };

  const handleDelete = async () => {
    try {
      const success = await deleteSite(site.id);
      if (success) {
        setShowModalUpdatesite(false);
        toast('Site deleted successfully', {
          type: 'success',
          position: 'bottom-right'
        });
        return dispatch(sendGetCompanyDetail({ cia_id: company.id }));
      }
    } catch (error) {
      toast('Error should be here', {
        type: 'error',
        position: 'bottom-right'
      });
    }
  };

  return (
    <Fragment>
      <div className="flex justify-between items-start w-[100%]">
        {/* SECTION Site information */}
        <div className="w-[100%] flex">
          {/* SECTION Site image */}
          {site.site_image && (
            <div className="rounded border w-[100px] h-[100px] flex p-[5px]">
              <img className="rounded object-contain" src={site.site_image} />
            </div>
          )}

          {/* !SECTION */}
          {/* SECTION Site info */}
          <div
            className="py-[10px] px-[20px]"
            style={{ width: 'calc(100% - 100px)' }}>
            {/* SECTION Site title */}
            <div className="flex items-end justify-start">
              <div className="text-2xl font-medium">
                <h1>{site.name}</h1>
              </div>
              <div className="w-[200px] pl-[10px]">
                <span
                  className="cursor-pointer h-[40px] text-[#00AEE8]"
                  onClick={() => setShowModalUpdatesite(true)}>
                  Edit site
                </span>
              </div>
            </div>
            {/* !SECTION */}
            <Space type={TYPE_SPACE.TEXT_DISTANCE} />
            {/* SECTION Site info */}
            <div className="flex w-full">
              <div className="max-w-[49%] min-w-[30%]">
                <LocationInfo
                  location={{
                    address: site.address,
                    address2: site.address2,
                    city: site.city,
                    state: site.state,
                    zip: site.zip
                  }}
                />
              </div>
              <Space classes="w-[2%]" />
              <div className="max-w-[49%]">
                <ContactInfo
                  contact={{
                    phone: site.contact_phone,
                    email: site.contact_email,
                    name: site.contact_name
                  }}
                />
              </div>
            </div>
            {/* !SECTION */}
          </div>
          {/* !SECTION */}
        </div>
        {/* !SECTION */}
      </div>
      {/* SECTION PopUp */}
      <ModalMiddle
        show={showModalUpdatesite}
        onClose={() => {
          setShowModalUpdatesite(false);
        }}>
        <SiteUpdateForm
          site={{
            id: site.id,
            address: site.address,
            address2: site.address2,
            city: site.city,
            payment: site.payment,
            state: site.state,
            zip: site.zip,
            name: site.name,
            schematic_url: site.schedule_image,
            logo_url: site.site_image,
            logo_id: site.id_image,
            schematic_id: site.id_esquematico,
            manager_email: site.contact_email,
            manager_fullname: site.contact_name,
            manager_phone: site.contact_phone
          }}
          title="Edit Site information"
          button="Update"
          onSubmit={submitUpdateSite}
          onDelete={handleDelete}
          type="update"
        />
      </ModalMiddle>
      {/* !SECTION */}
    </Fragment>
  );
};

export default SiteDetail;
