import { useEffect, useState } from 'react';
import { INPUT_CONTROL_STATE, TYPE_SPACE } from '../../../config/enum';
import { UpdateMDPDataForm } from '../../../interfaces/form';
import { InputControl, MDPGroup } from '../../../interfaces/form-control';
import {
  validateCellphone,
  validateCellphoneControl,
  validateEmailControl,
  validateMDPNameControl,
  validateName,
  validateNameControl,
  validateRequiredControl
} from '../../../libs/form-validation';
import { Button, ButtonPrimary, ButtonSecondary } from '../../FormInput/Button';
import InputText from '../../FormInput/InputText';
import Title from '../../FormInput/Title';
import Space from '../../Space';
import { validateMeterControl } from '../../../libs/form-validation';
import { validateApplianceControl } from '../../../libs/form-validation';
import { validateAmpsControl } from '../../../libs/form-validation';
import { validateSwitchgearControl } from '../../../libs/form-validation';
import { validateTransformerControl } from '../../../libs/form-validation';
import { title } from 'process';
import SelectPointInput from '../../FormInput/SelectPointInput';
import { IPointSelected } from '../../FormInput/SelectPointInput';
import mdpIcon from './../../../assets/img/mdp_icon.png';
import InputSelect, { IOption } from '../../FormInput/InputSelect';
import ElectripureService from '../../../service/electripure-service';
import { ResponseGeneric } from '../../../interfaces/base-service';
import { IGetGMTResponse } from '../../../interfaces/electripure-service';
import InputSelect2 from '../../FormInput/InputSelect2';
import { useDispatch } from 'react-redux';
import { deleteMdp, showToast } from '../../../actions/electripure';
import { toast } from 'react-toastify';

export interface IMdpForm {
  id: number;
  name: string;
  meterId: number;
  applianceId: number;
  amp: number;
  switchgear: number;
  transformer: number;
  gmt: number;
  positionX: number | null;
  positionY: number | null;
}

function MDPForm({
  onSubmit,
  schematic = null,
  mdp,
  title = 'Create MDP',
  button = 'Create',
  onDelete,
  type
}: {
  onSubmit: (data: Partial<IMdpForm>) => void;
  schematic?: any | null;
  mdp: Partial<IMdpForm>;
  title?: string;
  button?: string;
  onDelete?: () => void;
  type: 'create' | 'update';
}) {
  const [x, setX] = useState(mdp?.positionX ?? -1);
  const [y, setY] = useState(mdp?.positionY ?? -1);
  const [nameControl, setNameControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: mdp?.name ? `${mdp?.name}` : '',
    message: ''
  });
  const [meterControl, setMeterControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: mdp?.meterId ? `${mdp?.meterId}` : '',
    message: ''
  });
  const [applianceControl, setApplianceControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: mdp?.applianceId ? `${mdp?.applianceId}` : '',
    message: ''
  });
  const [ampControl, setAmpControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: mdp?.amp ? `${mdp?.amp}` : '',
    message: ''
  });
  const [switchgearControl, setSwitchgearControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: mdp?.switchgear ? `${mdp?.switchgear}` : '',
    message: ''
  });
  const [transformerControl, setTransformerControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: mdp?.transformer ? `${mdp?.transformer}` : '',
    message: ''
  });

  // SECTION Get GMTs
  const [gmtControl, setGmtControl] = useState({
    state: mdp.gmt ? INPUT_CONTROL_STATE.OK : INPUT_CONTROL_STATE.DEFAULT,
    value: mdp.gmt ? `${mdp.gmt}` : '',
    message: ''
  });
  const [gmtOptions, setGmtOptions] = useState<IOption[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const response: ResponseGeneric = await ElectripureService.listGMTs();
      const gmtOptions: IOption[] = (
        response.data as IGetGMTResponse
      ).GMT_ID.map((gmtId, index) => ({
        id: `${gmtId}`,
        value: (response.data as IGetGMTResponse).GMT_Name[index]
      }));
      setGmtOptions(gmtOptions);
    })();
  }, []);
  // useEffect(() => {
  //   console.log('GMTs', gmtOptions);
  // }, [gmtOptions]);
  // !SECTION

  function submit() {
    if (
      ampControl.state == INPUT_CONTROL_STATE.OK &&
      applianceControl.state == INPUT_CONTROL_STATE.OK &&
      meterControl.state == INPUT_CONTROL_STATE.OK &&
      gmtControl.state == INPUT_CONTROL_STATE.OK &&
      nameControl.state == INPUT_CONTROL_STATE.OK &&
      switchgearControl.state == INPUT_CONTROL_STATE.OK &&
      transformerControl.state == INPUT_CONTROL_STATE.OK
    ) {
      onSubmit({
        id: mdp.id,
        name: nameControl.value,
        meterId: parseInt(meterControl.value),
        applianceId: parseInt(applianceControl.value),
        amp: parseInt(ampControl.value),
        gmt: parseInt(gmtControl.value),
        switchgear: parseInt(switchgearControl.value),
        transformer: parseInt(transformerControl.value),
        positionX: x,
        positionY: y
      });
    } else {
      setAmpControl(validateRequiredControl(ampControl));
      setGmtControl(validateRequiredControl(gmtControl));
      setMeterControl(validateRequiredControl(meterControl));
      setNameControl(validateRequiredControl(nameControl));
      setSwitchgearControl(validateRequiredControl(switchgearControl));
      setTransformerControl(validateRequiredControl(transformerControl));
      setApplianceControl(validateRequiredControl(applianceControl));
    }
  }

  return (
    <div className="w-full bg-color-white p-[10px]">
      <Space type={TYPE_SPACE.INPUT_DISTANCE} />
      <div
        className="mx-auto w-full max-w-[650px]"
        style={{ textAlign: 'center' }}>
        <Title title={title} />
      </div>
      <div>
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <InputText
          name={'name'}
          placeholder="MDP name"
          label="MDP Name"
          defaultValue={mdp.name}
          onChange={(value: string) => {
            const newMdpNameControl = validateMDPNameControl(value);
            setNameControl(newMdpNameControl);
          }}
          state={nameControl.state}
          message={nameControl.message}
        />
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <InputText
          name={'meter'}
          placeholder="123456"
          label="Meter ID"
          defaultValue={mdp.meterId ? `${mdp.meterId}` : ''}
          onChange={(value: string) => {
            const newMdpMeterControl = validateMeterControl(value);
            setMeterControl(newMdpMeterControl);
          }}
          state={meterControl.state}
          message={meterControl.message}
        />
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <InputText
          name={'appliance'}
          placeholder="123456"
          label="Appliance ID"
          defaultValue={mdp.applianceId ? `${mdp.applianceId}` : ''}
          onChange={(value: string) => {
            const newMdpApplicanceControl = validateApplianceControl(value);
            setApplianceControl(newMdpApplicanceControl);
          }}
          state={applianceControl.state}
          message={applianceControl.message}
        />
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <InputText
          name={'ampCap'}
          placeholder="400"
          label="MDP Amp cap"
          defaultValue={mdp.amp ? `${mdp.amp}` : ''}
          onChange={(value: string) => {
            const newMdpAmpControl = validateAmpsControl(value);
            setAmpControl(newMdpAmpControl);
          }}
          state={ampControl.state}
          message={ampControl.message}
        />
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <InputText
          name={'switchgearCap'}
          placeholder="2000"
          label="Switchgear cap"
          defaultValue={mdp.switchgear ? `${mdp.switchgear}` : ''}
          onChange={(value: string) => {
            const newMdpSwitchgearControl = validateSwitchgearControl(value);
            setSwitchgearControl(newMdpSwitchgearControl);
          }}
          state={switchgearControl.state}
          message={switchgearControl.message}
        />
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <InputText
          name={'transformer'}
          placeholder="2000"
          label="Transformer"
          defaultValue={mdp.transformer ? `${mdp.transformer}` : ''}
          onChange={(value: string) => {
            const newMdpTransformerControl = validateTransformerControl(value);
            setTransformerControl(newMdpTransformerControl);
          }}
          state={transformerControl.state}
          message={transformerControl.message}
        />
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <InputSelect2
          name="gmt"
          label="GMT"
          options={gmtOptions}
          placeholder="Select timezone"
          state={gmtControl.state}
          value={gmtControl.value}
          message={gmtControl.message}
          onChange={(select: { value: any; id: any }) => {
            setGmtControl({
              state: INPUT_CONTROL_STATE.OK,
              message: '',
              value: select.id
            });
          }}
        />
      </div>
      {schematic ? (
        <div>
          <Space type={TYPE_SPACE.INPUT_DISTANCE} />
          <SelectPointInput
            pointConfig={{
              height: 40,
              width: 40,
              src: mdpIcon
            }}
            defaultPointSelected={{
              x: x,
              y: y
            }}
            onChange={(point: IPointSelected) => {
              setX(point.x);
              setY(point.y);
            }}
            img={schematic}
          />
        </div>
      ) : (
        ''
      )}
      <div className="flex gap-x-12 mt-12">
        <ButtonPrimary onClick={submit}>{button}</ButtonPrimary>
        {type == 'update' && (
          <button className="bg-red-800 w-full text-white" onClick={onDelete}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
}

export default MDPForm;
