import {
  ActionNotification,
  AddTaskPayload,
  FilterAmpsDataPayload,
  FilterVoltsDataPayload,
  LoginPayload,
  SendAddContactPayload,
  SendCreateUserPayload,
  SendActivateDeactivateUserPayload,
  SendForgotPasswordPayload,
  SendGetAmpsDataPayload,
  SendGetCompaniesByUserPayload,
  SendGetCompaniesPayload,
  SendGetCompaniesTablePayload,
  SendGetUsersPayload,
  SendImagePayload,
  SendFilePayload,
  sendUploadFileDataPayload,
  SendResendEmailPayload,
  SendUpdatePasswordPayload,
  SendUpdateUserPayload,
  SendValidateTokenPayload,
  SendVerificationCodePayload,
  SendVerificationEmailPayload,
  SetAmpsDataPayload,
  SetCompaniesPayload,
  SetCompaniesTablePayload,
  SetCurrentUserPayload,
  SetGlobalCompaniesPayload,
  SetJwtPayload,
  SetLoadingPayload,
  SetLoginTokenPayload,
  SetPasswordTokenPayload,
  SetPasswordUserPayload,
  SetTimestampTwoStepVerificationPayload,
  SetUsersPayload,
  SetVoltsDataPayload,
  ShowToastPayload,
  SendActivateDeactivateCompanyPayload,
  SetCompanyDetailPayload,
  ValidateUpdateUserPayload,
  SetDevicesTablePayload,
  SendGetUploadedFilesPayload,
  SetUploadedFilePayload,
  GenerateFCMTokenPayload,
  SetRememberTokenPayload,
  SetFCMTokenPayload,
  SaveRememberTokenPayload,
  SendArchiveCompanyPayload,
  SetSessionPayload,
  ISetNotificationsPayload
} from '../interfaces/actions';
import {
  ADD_TASK,
  FILTER_AMPS_DATA,
  FILTER_VOLTS_DATA,
  LOGIN,
  SET_AMPS_DATA,
  SET_COMPANIES,
  SET_COMPANIES_TABLE,
  SET_COMPANY_DETAIL,
  SET_CURRENT_USER,
  SET_GLOBAL_COMPANIES,
  SET_JWT,
  SET_LOADING,
  SET_LOGIN_TOKEN,
  SET_PASSWORD_TOKEN,
  SET_PASSWORD_USER,
  SET_TIMESTAMP_TWO_STEP_VERIFICATION,
  SET_USERS,
  SET_VOLTS_DATA,
  SHOW_TOAST,
  SET_DEVICES_TABLE,
  SET_UPLOADED_FILES,
  SET_REMEMBERTOKEN,
  SET_FCMTOKEN,
  GET_NOTIFICATIONS,
  ADD_NOTIFICATION
} from './types';
import ElectripureService from '../service/electripure-service';
import { ResponseGeneric } from '../interfaces/base-service';

// Mappers
import UserMapper from './../mappers/user-mapper';
import {
  CompanyEntity,
  CompanyRowEntity,
  GlobalCompanyEntity,
  UploadedFileEntity,
  UserEntity
} from '../interfaces/entities';
import {
  AddContactRequest,
  INotification
} from '../interfaces/electripure-service';
import CompanyMapper from '../mappers/company-mapper';
import { TASK_STATE } from '../config/enum';
import FileMapper from '../mappers/file-mapper';
import { generateToken } from '../utils/firebase';
import { deleteCookie, getCookie, setCookie } from '../libs/cookie';
import { parseJwt } from '../libs/jwt';

export const setFcmToken = (
  payload: SetFCMTokenPayload
): ActionNotification => ({
  type: SET_FCMTOKEN,
  payload: payload
});

export const setRememberToken = (
  payload: SetRememberTokenPayload
): ActionNotification => ({
  type: SET_REMEMBERTOKEN,
  payload: payload
});

export const setLoading = (payload: SetLoadingPayload): ActionNotification => ({
  type: SET_LOADING,
  payload: payload
});

export const setTimestampTwoStepVerification = (
  payload: SetTimestampTwoStepVerificationPayload
): ActionNotification => ({
  type: SET_TIMESTAMP_TWO_STEP_VERIFICATION,
  payload: payload
});

export const setJwt = (payload: SetJwtPayload): ActionNotification => ({
  type: SET_JWT,
  payload: payload
});

export const setLoginToken = (
  payload: SetLoginTokenPayload
): ActionNotification => ({
  type: SET_LOGIN_TOKEN,
  payload: payload
});

export const setPasswordToken = (
  payload: SetPasswordTokenPayload
): ActionNotification => ({
  type: SET_PASSWORD_TOKEN,
  payload: payload
});

export const setPasswordUser = (
  payload: SetPasswordUserPayload
): ActionNotification => ({
  type: SET_PASSWORD_USER,
  payload: payload
});

export const showToast = (payload: ShowToastPayload): ActionNotification => ({
  type: SHOW_TOAST,
  payload: payload
});

export const setUsers = (payload: SetUsersPayload) => ({
  type: SET_USERS,
  payload: payload
});

export const setCompanies = (payload: SetCompaniesPayload) => ({
  type: SET_COMPANIES,
  payload: payload
});

export const setUploadedFile = (payload: SetUploadedFilePayload) => ({
  type: SET_UPLOADED_FILES,
  payload: payload
});

export const setGlobalCompanies = (payload: SetGlobalCompaniesPayload) => ({
  type: SET_GLOBAL_COMPANIES,
  payload: payload
});

export const setCompaniesTable = (payload: SetCompaniesTablePayload) => ({
  type: SET_COMPANIES_TABLE,
  payload: payload
});

export const addTask = (payload: AddTaskPayload) => ({
  type: ADD_TASK,
  payload: payload
});
export const setCurrentUser = (payload: SetCurrentUserPayload) => ({
  type: SET_CURRENT_USER,
  payload: payload
});

export const setAmpsData = (payload: SetAmpsDataPayload) => ({
  type: SET_AMPS_DATA,
  payload: payload
});

export const setVoltsData = (payload: SetVoltsDataPayload) => ({
  type: SET_VOLTS_DATA,
  payload: payload
});

export const filterAmpsData = (payload: FilterAmpsDataPayload) => ({
  type: FILTER_AMPS_DATA,
  payload: payload
});

export const filterVoltsData = (payload: FilterVoltsDataPayload) => ({
  type: FILTER_VOLTS_DATA,
  payload: payload
});

export const setCompanyDetail = (payload: SetCompanyDetailPayload) => ({
  type: SET_COMPANY_DETAIL,
  payload: payload
});

export const setDevicesTable = (payload: SetDevicesTablePayload) => ({
  type: SET_DEVICES_TABLE,
  payload: payload
});

export const setNotifications = (payload: ISetNotificationsPayload) => ({
  type: GET_NOTIFICATIONS,
  payload: payload
});

export const addNotification = (payload: ISetNotificationsPayload) => ({
  type: ADD_NOTIFICATION,
  payload: payload
});

// SECTION login

/**
 *  Set session
 * - Set jwt token
 * - Set current user
 * @param {SetSessionPayload} payload payload
 * @return {object}
 */
export const setSession =
  (payload: SetSessionPayload): any =>
  async (dispatch: any) => {
    const jwt: string = payload.jwt;
    const session: any = parseJwt(jwt);
    localStorage.setItem('electripureJwt', jwt);
    localStorage.setItem('current_user', session.fullname);
    localStorage.setItem('user_id', session.id);
    dispatch(
      setJwt({
        token: jwt
      })
    );
    dispatch(
      setCurrentUser({
        fullname: session.fullname,
        id: session.id,
        role: session.role
      })
    );
  };

/**
 * Request login
 * - Validate if recovered session using token
 * - Validate if user is active
 * - Validate if credentials are correct
 * - Set login token for two step verification
 *
 * @param {LoginPayload} payload payload
 * @return {object}
 */
export const login =
  (payload: LoginPayload): any =>
  async (dispatch: any) => {
    // SECTION Request login
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.login({
      email: payload.email,
      password: payload.password,
      token2: payload.rememberToken,
      flag_remember: payload.remember
    });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    // !SECTION

    // SECTION Validate if recovered session
    if (response.data.validate_token) {
      const jwt: string = response.data.JWT;
      dispatch(
        setSession({
          jwt: jwt
        })
      );
      return;
    }
    // !SECTION

    // SECTION Delete cookie remember
    deleteCookie('rememberToken');
    // !SECTION

    // SECTION Validate possible errors
    if (response.data.password_correct !== 'True') {
      return dispatch(
        showToast({
          message: 'Credentials invalid.',
          status: 'error'
        })
      );
    }
    if (response.data.activated !== 'True') {
      return dispatch(
        showToast({
          message: 'Account deactivate.',
          status: 'error'
        })
      );
    }
    // !SECTION
    // SECTION Set login token
    dispatch(
      setLoginToken({
        token: response.data.token
      })
    );
    // !SECTION
    dispatch(
      showToast({
        message: 'Log in success!',
        status: 'success'
      })
    );
  };

export const sendVerificationEmail =
  (payload: SendVerificationEmailPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.authorizationCode({
        tipo: 'email',
        token_login: payload.token
      });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    if (response.data.log != 'Email Sent') {
      return dispatch(
        showToast({
          message: 'Email not sent.',
          status: 'error'
        })
      );
    }
    dispatch(
      setTimestampTwoStepVerification({
        timestamp: new Date().getTime()
      })
    );
    return dispatch(
      showToast({
        message: 'Email sent!',
        status: 'success'
      })
    );
  };

export const sendVerificationCode =
  (payload: SendVerificationCodePayload): any =>
  async (dispatch: any) => {
    // SECTION Request verification code
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.authorizationCodeValidate({
        token_login: payload.token,
        code_auth: payload.code
      });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    if (response.data.log != 'Code Correct') {
      return dispatch(
        showToast({
          message: 'Code incorrect.',
          status: 'error'
        })
      );
    }
    // !SECTION

    // SECTION Set remember token
    // if (response.data.token2) {
    //   dispatch(
    //     saveRememberToken({
    //       token: response.data.token2
    //     })
    //   );
    // }
    dispatch(
      saveRememberToken({
        token: response.data.token2
      })
    );
    // !SECTION

    // SECTION Set session
    dispatch(
      setSession({
        jwt: `${response.data['token']}`
      })
    );
    // !SECTION

    dispatch(
      showToast({
        message: 'Code correct!.',
        status: 'success'
      })
    );
  };

// !SESSION
// SESSION Forgot Password
export const sendForgotPassword =
  (payload: SendForgotPasswordPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.forgotPassword({
      email: payload.email
    });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    if (!response.data.Send) {
      return dispatch(
        showToast({
          message: 'Email not sent!',
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: 'Email sent!',
        status: 'success'
      })
    );
    return;
  };

export const sendUpdatePassword =
  (payload: SendUpdatePasswordPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.updatePassword({
      password: payload.password,
      token: payload.token
    });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    if (!response.data.Send) {
      return dispatch(
        showToast({
          message: 'Password not updated.',
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: 'Password updated!',
        status: 'success'
      })
    );
    return;
  };

// Dashboard user

export const sendGetUsers =
  (payload: SendGetUsersPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.getUsers();
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    const users: UserEntity[] = UserMapper.toUsers(response.data);
    dispatch(
      setUsers({
        users: users
      })
    );
  };

export const sendGetCompaniesByUser =
  (payload: SendGetCompaniesByUserPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.getCompaniesByUser({
        id_user: payload.userId
      });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }

    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    const companies: CompanyEntity[] = UserMapper.toCompanies(response.data);
    dispatch(
      setCompanies({
        companies: companies
      })
    );
  };

export const sendResentEmail =
  (payload: SendResendEmailPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.resendEmail(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: 'Email sent!',
        status: 'success'
      })
    );
  };

export const sendActivateDeactivateUser =
  (payload: SendActivateDeactivateUserPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.toogleUserState({
      user_id: payload.id
    });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: `User ${payload.action + 'd'}!`,
        status: 'success'
      })
    );
    dispatch(sendGetUsers({}));
  };

export const sendCreateUser =
  (payload: SendCreateUserPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.createUser(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: 'User created!',
        status: 'success'
      })
    );
    dispatch(sendGetUsers({}));
  };

export const sendGetCompaniesTable =
  (payload: SendGetCompaniesTablePayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.getCompaniesTable();
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    const companies: CompanyRowEntity[] = CompanyMapper.toCompaniesRows(
      response.data
    );
    dispatch(
      setCompaniesTable({
        companies: companies
      })
    );
  };

export const sendGetCompanies =
  (payload: SendGetCompaniesPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.getCompanies();
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    const companies: GlobalCompanyEntity[] = CompanyMapper.toCompanies(
      response.data
    );
    dispatch(
      setGlobalCompanies({
        companies: companies
      })
    );
  };

// Create password stepper

export const sendUpdateUser =
  (payload: SendUpdateUserPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.updateUser(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: 'Account updated successfully!',
        status: 'success'
      })
    );
  };

export const sendValidateToken =
  (payload: SendValidateTokenPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.validateToken(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    if (!response.data.token_validated) {
      dispatch(
        setPasswordToken({
          token: null
        })
      );
      return;
    }
    dispatch(
      setPasswordUser({
        email: response.data.email
      })
    );
    dispatch(
      setPasswordToken({
        token: payload.token
      })
    );
  };

export const validateUpdateUser =
  (payload: ValidateUpdateUserPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.validateUpdateUser(payload);
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    if (!response.data.validate) {
      dispatch(
        showToast({
          message:
            'Email or cell phone do not match those used at the time of creating your account.',
          status: 'error'
        })
      );
      return;
    }
    dispatch(sendUpdateUser(payload));
  };

// Create backup contact

export const sendAddContacts =
  (payload: SendAddContactPayload[]): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    await Promise.all(
      payload.map(async (contact: SendAddContactPayload, index: number) => {
        //TODO Email tiene que ser tomado desde el jwt
        const payload: AddContactRequest = {
          user_email: localStorage.getItem('email')!,
          contact_name: contact.name,
          contact_email: contact.email,
          contact_cellphone: contact.phone
        };
        const responseAddContact: ResponseGeneric =
          await ElectripureService.addContact(payload);
        if (responseAddContact.success) {
          dispatch(
            showToast({
              message: `Contact ${index + 1} created!`,
              status: 'success'
            })
          );
        } else {
          dispatch(
            showToast({
              message: responseAddContact.error!,
              status: 'error'
            })
          );
        }
      })
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    dispatch(
      showToast({
        message: `Contacts created!`,
        status: 'success'
      })
    );
  };

// Create company

export const SendImage =
  (payload: SendImagePayload): any =>
  async (dispatch: any) => {
    dispatch(
      addTask({
        key: payload.taskKey,
        state: TASK_STATE.PENDING,
        result: null
      })
    );
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.uploadImage({
      image: payload.base64
    });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      addTask({
        key: payload.taskKey,
        state: TASK_STATE.COMPLETED,
        result: response.data.id
      })
    );
    dispatch(
      showToast({
        message: 'Image upload!',
        status: 'success'
      })
    );
  };

export const sendActivateDeactivateCompany =
  (payload: SendActivateDeactivateCompanyPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.toogleCompanyState({
        company_id: payload.id
      });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: `Company ${payload.action + 'd'}!`,
        status: 'success'
      })
    );
    dispatch(sendGetCompaniesTable({}));
    dispatch(sendGetCompanyDetail({ cia_id: payload.id }));
  };

/**
 * Archivate company by id
 * @param payload
 * @returns
 */
export const sendArchiveCompany =
  (payload: SendArchiveCompanyPayload): any =>
  async (dispatch: any) => {
    // SECTION Archivate company
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.archiveCompany({
      company_id: payload.id
    });
    dispatch(
      setLoading({
        loading: false
      })
    );
    // !SECTION
    // SECTION Validate response
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: `Company archivated!`,
        status: 'success'
      })
    );
    // !SECTION
    // SECTION Update companies list
    dispatch(sendGetCompaniesTable({}));
    // !SECTION
  };

export const sendAddCompany =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.createCompany(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }

    // SECTION Validate response
    //   {
    //     "data": null,
    //     "error": {
    //         "message": "Company Created"
    //     },
    //     "status": 200
    // }
    const { data, error, status } = response.data;

    if (error != null) {
      dispatch(
        showToast({
          message: error.message,
          status: 'error'
        })
      );
    } else {
      if (data.Log) {
        dispatch(
          showToast({
            message: 'Company created!',
            status: 'success'
          })
        );
      } else {
        dispatch(
          showToast({
            message: 'Problem creating company!',
            status: 'error'
          })
        );
        return;
      }
    }
    // !SECTION
    return;
  };

export const sendUpdateCompany =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.updateCompany(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: 'Company updated!',
        status: 'success'
      })
    );
    dispatch(sendGetCompanyDetail({ cia_id: payload.company_id }));
    return;
  };

export const sendUpdateSite =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.updateSite(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: 'Site updated!',
        status: 'success'
      })
    );
    dispatch(sendGetCompanyDetail({ cia_id: payload.company_id }));
    return;
  };

export const sendUpdateMDP =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.updateMDP(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: 'MDP updated!',
        status: 'success'
      })
    );
    dispatch(sendGetCompanyDetail({ cia_id: payload.company_id }));
    return;
  };

export const sendUpdateUserDetails =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.updateUserDetails(payload);
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: 'User updated!',
        status: 'success'
      })
    );
    return;
  };

export const sendGetCompanyDetail =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.companyDetail(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    const ciaDetail: SetCompanyDetailPayload = response.data;
    console.log({ ciaDetail });
    dispatch(setCompanyDetail(ciaDetail));
  };

export const sendCreateMDP =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.createMDP(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: 'MDP Created!',
        status: 'success'
      })
    );
    dispatch(sendGetCompanyDetail({ cia_id: payload.company_id }));
    return;
  };

export const sendCreateSite =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.createSite(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');

      return;
    }
    if (response.statusCode === 400) {
      dispatch(
        showToast({
          message: response.data.message,
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: 'Site Created!',
        status: 'success'
      })
    );
    dispatch(sendGetCompanyDetail({ cia_id: payload.company_id }));
    return true;
  };

export const sendGetDevicesTable =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.getDevicesTable(
      payload
    );
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    const devices = response.data;
    dispatch(setDevicesTable(devices));
  };

export const sendUpdateDeviceDetails =
  (payload: any): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.updateDeviceDetails(payload);
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: 'Device Serial Updated!',
        status: 'success'
      })
    );
    dispatch(sendGetDevicesTable({}));
    return;
  };

export const sendUploadFile =
  (payload: SendFilePayload): any =>
  async (dispatch: any) => {
    dispatch(
      addTask({
        key: payload.taskKey,
        state: TASK_STATE.PENDING,
        result: null
      })
    );
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.uploadFile({
      file: payload
    });

    dispatch(
      setLoading({
        loading: false
      })
    );

    if (!response.success) {
      dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
      return;
    }

    dispatch(
      addTask({
        key: payload.taskKey,
        state: TASK_STATE.COMPLETED,
        result: response.data.id_file
      })
    );

    dispatch(
      showToast({
        message: 'File upload!',
        status: 'success'
      })
    );
  };

export const sendUploadFileData =
  (payload: sendUploadFileDataPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric = await ElectripureService.uploadFileData(
      payload
    );

    dispatch(
      setLoading({
        loading: false
      })
    );

    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }

    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    dispatch(
      showToast({
        message: 'File Registred Successfully in Company!',
        status: 'success'
      })
    );
    return;
  };
// Amps and Vots

// export const sendGetAmpsDataGraph = (payload: SendGetAmpsDataPayload): any => (async (dispatch: any) => {
//     dispatch(setLoading({
//         loading: true
//     }));
//     const response: ResponseGeneric= await ElectripureService.getAmpsDataGraph({
//         date_min: payload.dateMin,
//         device: payload.device
//     });
//     dispatch(setLoading({
//         loading: false
//     }));
//     if(!response.success) {
//         dispatch(showToast({
//             message: response.error!,
//             status: "error"
//         }));
//         return;
//     };
//     let data: any = response.data;
//     dispatch(setAmpsData({
//         "data": {
//             "Amps Line A": data["A1_data"],
//             "Amps Line B": data["A2_data"],
//             "Amps Line C": data["A3_data"],
//             "timestamp": data["TS_data"]
//         }
//     }));
// });

export const sendGetUploadedFiles =
  (payload: SendGetUploadedFilesPayload): any =>
  async (dispatch: any) => {
    dispatch(
      setLoading({
        loading: true
      })
    );
    const response: ResponseGeneric =
      await ElectripureService.getListFilesCompany({
        id_company: payload.companyId
      });
    dispatch(
      setLoading({
        loading: false
      })
    );
    if (response.data.message == 'Token is invalid!') {
      dispatch(
        setTimestampTwoStepVerification({
          timestamp: null
        })
      );
      dispatch(
        setLoginToken({
          token: null
        })
      );
      dispatch(
        setJwt({
          token: null
        })
      );
      localStorage.removeItem('electripureJwt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('current_user');
    }
    if (!response.success) {
      return dispatch(
        showToast({
          message: response.error!,
          status: 'error'
        })
      );
    }
    const uploadedFiles: UploadedFileEntity[] = FileMapper.toUploadedFiles(
      response.data.table_files
    );
    dispatch(setUploadedFile({ uploadedFiles: uploadedFiles }));
  };

export const generateFCMToken =
  (payload: GenerateFCMTokenPayload): any =>
  async (dispatch: any) => {
    const token = await generateToken(payload.messaging);
    if (token) {
      dispatch(
        setFcmToken({
          token: token
        })
      );
      const response: ResponseGeneric =
        await await ElectripureService.saveFCMToken({
          fcmtoken: token
        });
      // console.log("FCM TOKEN RESPONSE", response);
    }
  };

export const recoveryRememberToken =
  (payload: any): any =>
  async (dispatch: any) => {
    const value: string | null = getCookie('rememberToken');
    if (value != null) {
      dispatch(
        setRememberToken({
          token: value
        })
      );
    }
  };

export const saveRememberToken =
  (payload: SaveRememberTokenPayload): any =>
  async (dispatch: any) => {
    setCookie('rememberToken', payload.token);
    dispatch(
      setRememberToken({
        token: payload.token
      })
    );
  };

export const getNotifications = (): any => async (dispatch: any) => {
  const response: ResponseGeneric = await ElectripureService.getNotifications();
  if (response.success) {
    const notifications: INotification[] = response.data
      .data as INotification[];
    dispatch(
      setNotifications({
        notifications: notifications
      })
    );
  }
};

export const pushNotification = (): any => async (dispatch: any) => {
  //dispatch(addNotification());
};

export const getConfigByMdp =
  (mdpId: number, callback?: (data: any) => void): any =>
  async (dispatch: any) => {
    const response: ResponseGeneric = await ElectripureService.getConfigByMdp(
      mdpId
    );
    if (response.success) {
      callback && callback(response.data);
    }
  };

export const deleteConfigByMdpAlert =
  (alertId: number, callback?: (data: any) => void): any =>
  async (dispatch: any) => {
    const response: ResponseGeneric =
      await ElectripureService.deleteConfigByMdpAlert(alertId);
    if (response.success) {
      callback && callback(response.data);
    }
  };

export const deleteConfigByMdpEvent =
  (eventId: number, callback?: (data: any) => void): any =>
  async (dispatch: any) => {
    const response: ResponseGeneric =
      await ElectripureService.deleteConfigByMdpEvent(eventId);
    if (response.success) {
      callback && callback(response.data);
    }
  };

export const setConfigByMdp =
  (mdpId: number, data: any, callback?: (data: any) => void): any =>
  async (dispatch: any) => {
    const response: ResponseGeneric = await ElectripureService.setConfigByMdp(
      mdpId,
      data
    );
    console.log('AAAAA 2', response.data);
    if (response.success) {
      dispatch(showToast({ message: 'Config updated!', status: 'success' }));
      dispatch(
        getConfigByMdp(mdpId, (data: any) => {
          if (callback) callback(data);
        })
      );
    }
  };

export const deleteMdp = async ({ meterId, cid }: any) => {
  try {
    await ElectripureService.deleteMdp(meterId);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteSite = async (siteId: number) => {
  try {
    await ElectripureService.deleteSite(siteId);
    return true;
  } catch (error) {
    console.error(error);
    throw new Error('Could not delete site');
  }
};

export const deleteUser = async (userId: number) => {
  try{
    await ElectripureService.deleteUser(userId);
    return true;
  }catch(error){
    console.error(error);
    throw new Error('Could not delete user')
  }
}