import { useSelector } from 'react-redux';
import { ElectripureState } from '../../../interfaces/reducers';
import { parseJwt } from '../../../libs/jwt';
import { Fragment } from 'react';

function ArchivatePermission({ children }: { children: React.ReactNode }) {
  const jwt: string | null = useSelector(
    (state: ElectripureState) => state.electripureJwt
  );
  if (!jwt || jwt === null || jwt !== '') {
    return <Fragment></Fragment>;
  }
  const user: any = parseJwt(jwt);
  const hasPermission: boolean = user['role']['activate_company'] == 1;
  return <Fragment>{hasPermission ? children : ''}</Fragment>;
}

export default ArchivatePermission;
