import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CompanyEntity } from '../../../interfaces/entities';
import { ElectripureState } from '../../../interfaces/reducers';
import img from './item_img.svg';
import Site from './components/Site';

import './styles.module.css';

const SelectedCompanies = ({ companySelected }: any) => {
  const company = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  ).filter(function (a: any) {
    return a.company_name === companySelected;
  });

  const companyArray: any = Array.from(company);

  return (
    <Fragment>
      <div
        className="ml-[5%] w-[90%] h-[100vh] overflow-y-scroll sites-list"
        style={{ height: 'calc(100vh - 184px)' }}>
        {companyArray.length !== 0
          ? companyArray[0]['list_sites'].map((site: any, site_index: any) => (
              <Site key={site_index} site={site} />
            ))
          : ''}
      </div>
    </Fragment>
  );
};

export default SelectedCompanies;
