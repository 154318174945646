import { useEffect } from 'react';
import { INPUT_CONTROL_STATE, ORIENTATION_INPUT } from '../../../config/enum';
import './style.css';

function InputCheckboxIcon({
  name,
  children,
  onChange,
  tooltip,
  classes = '',
  defaultChecked = false,
  disabled = false
}: {
  name: string;
  tooltip?: string;
  children: any;
  onChange: (checked: boolean) => void;
  classes?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
}) {
  function handleChange(event: any) {
    onChange(event.target.checked);
  }

  return (
    <div className="">
      <div className="w-full flex justify-start items-center relative">
        <input
          checked={defaultChecked}
          type="checkbox"
          disabled={disabled}
          onChange={handleChange}
          className={`w-[20px] border h-[20px] border-color-black-light color-black hidden`}
          id={name}
        />
        <label
          htmlFor={name}
          className={`${
            !disabled ? 'cursor-pointer' : ''
          } f-medium color-black p-[2px] border border-color-black-light border-1 ${classes}`}>
          <div className="tooltip_activator">{children}</div>
          {tooltip ? (
            <div className="absolute top-0 left-[50%] tooltip opacity-1 bg-color-primary color-white px-4 py-1 rounded-md">
              {tooltip}
            </div>
          ) : (
            ''
          )}
        </label>
      </div>
    </div>
  );
}

export default InputCheckboxIcon;
