import { IMdp, IPointConfig, ISite } from '../interfaces';

function MdpSchematic({
  site,
  pointConfig
}: {
  site: ISite;
  pointConfig: IPointConfig;
}) {
  console.log(site);
  if (
    site.schedule_image == '' ||
    site.schedule_image == null ||
    site.schedule_image == undefined
  ) {
    return (
      <div className="flex w-[100%] relative">
        {/* <img src="https://via.placeholder.com/150" className="w-[100%]" /> */}
      </div>
    );
  }
  return (
    <div className="flex w-[100%] relative">
      <img src={site.schedule_image} className="w-[100%]" />
      {site.mdps.map((mdp: IMdp) => {
        if (mdp.location && mdp.location.x && mdp.location.y) {
          return (
            <div
              className={`absolute group`}
              style={{
                width: `${pointConfig.width}px`,
                height: `${pointConfig.height}px`,
                left: `calc(${mdp.location.x}% - ${pointConfig.width / 2}px)`,
                top: `calc(${mdp.location.y}% - ${pointConfig.height / 2}px)`
              }}>
              <div
                className={`top-0 left-0 absolute flex justify-center w-full translate-y-0 opacity-0 group-hover:opacity-100 group-hover:translate-y-[-120%]`}
                style={{
                  transition: 'all 0.3s ease'
                }}>
                {pointConfig.onToltip(mdp)}
              </div>

              <img
                className={`top-0 left-0 absolute`}
                src={pointConfig.src}
                width={pointConfig.width}
                height={pointConfig.height}
              />
            </div>
          );
        }
        return '';
      })}
    </div>
  );
}

export default MdpSchematic;
