import { useParams } from 'react-router';
import {
  YConfig,
  ChannelConfig,
  ChannelDataPowerLog,
  AxisConfigDict,
  PowerLogConfig
} from '../../../interfaces/graph';
import environment from '../../../config/env';
import PowerLogGraph from '../PowerLogGraph';
import { useSelector } from 'react-redux';
import { ElectripureState } from '../../../interfaces/reducers';
import { useMemo } from 'react';
// List of apis for request data for graph, Total graph will be displayed based on the length of this list
// Key = channel key
// Label = channel label (Display Name)
// Url = api url for request data for graph
// For this example, we using 7 channel for graph/ Each channel will have many datasets.
const graphMetadata: ChannelConfig[] = [
  {
    key: 'aa',
    label: 'A[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_A(A)_v2`
  },
  {
    key: 'ba',
    label: 'B[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_B(A)_v2`
  },
  {
    key: 'ca',
    label: 'C[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_C(A)_v2`
  },
  {
    key: 'na',
    label: 'N[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_N(A)_v2`
  },
  {
    key: 'av',
    label: 'A[V]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_A(V)_v2`
  },
  {
    key: 'bv',
    label: 'B[V]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_B(V)_v2`
  },
  {
    key: 'cv',
    label: 'C[V]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_C(V)_v2`
  }
  // {
  //   "key": "gv",
  //   "label": "G(V)",
  //   "url": "https://apidev.electripuremeter.com/get_chart1_G(V)_v2"
  // }
];

// Configuration of y axis for graph (Datasets configurations)
const yConfigs: YConfig[] = [
  // {
  //   key: 'max',
  //   color: '#fc0303',
  //   labelList: ['Maximum'],
  //   label: 'Maximum',
  //   labelShort: 'Maximum',
  //   groupList: ['Display'],
  //   axisGroup: 'y'
  // },
  {
    key: 'average',
    color: '#000000',
    labelList: ['Average'],
    label: 'Average',
    labelShort: 'Average',
    groupList: ['Display'],
    axisGroup: 'y'
  }
  // {
  //   key: 'min',
  //   color: '#00ff3c',
  //   labelList: ['Minimum'],
  //   label: 'Minimum',
  //   labelShort: 'Minimum',
  //   groupList: ['Display'],
  //   axisGroup: 'y'
  // }
];

// Method for conver api response to data for graph
function toData(channel: ChannelConfig, result: any): ChannelDataPowerLog {
  return {
    x: result['TS_data'],
    x_label: result['TS_data_label'],

    timestamp: result['TS_unix'],
    y: {
      max: result['MAX_data'],
      min: result['MIN_data'],
      average: result['AVERAGE_data']
    },
    backgroundRanges: [
      {
        color: 'rgba(200, 0, 0, 0.5)',
        from: 100,
        to: 200
      }
    ],
    y_type_point: {
      max: result['MAX_data'].map(() => {
        return 'Normal';
      }),
      min: result['MIN_data'].map(() => {
        return 'Normal';
      }),
      average: result['AVERAGE_data'].map(() => {
        const status = ['Error', 'Warning', 'Normal'];
        return status[Math.floor(Math.random() * 3)];
      })
    }
  };
}
// SECTION Mapper for config for each y dataset
// NOTE Power log graph will group all the declared groups and also the labels declared for each group (respecting the index of label and group). For a group to be displayed its labels must be active.
const axisConfigDict: AxisConfigDict = {
  y: {
    position: 'left',
    type: 'linear'
  } // Config for y axis
};
// !SECTION

const powerLogConfig: PowerLogConfig = {
  threshold: 0 // Number of graph visible for hide channel and display
};

function VoltageCurrentGraph({ defaultMeterId }: { defaultMeterId?: number }) {
  // get deviceId [Not editable]
  let { meterId } = useParams();
  let deviceId = defaultMeterId ?? parseInt(meterId!);

  // const companies = JSON.parse(
  //   useSelector((state: ElectripureState) => state.companies)
  // );

  // const companyChartDetails = companies.find(({ company_name, list_sites }) => {
  //   const site = list_sites.find((site) =>
  //     site.mdp_list.find((mdp) => mdp.meter_id === deviceId)
  //   );
  //   return site;
  // });

  // console.log({ companyChartDetails });

  return (
    <>
      <PowerLogGraph
        config={powerLogConfig}
        yConfigList={yConfigs}
        toData={toData}
        deviceId={deviceId}
        axisConfigDict={axisConfigDict}
        channelConfigList={graphMetadata}
      />
    </>
  );
}

export default VoltageCurrentGraph;
