import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { sendGetCompanyDetail } from '../../actions/electripure';
import { ElectripureState } from '../../interfaces/reducers';
import DataTableUploadFiles from '../../components/DataTables/DataTableUploadFiles';
import { ICompany } from './interfaces';
import CompanyDetails from './CompanyDetail';
import Sites from './Sites';

const CompanyDetailsPage = () => {
  // SECTION Instance hooks
  const dispatch = useDispatch();
  // !SECTION
  // SECTION Get company id from url
  let { ciaId } = useParams();
  let companyId: number = parseInt(ciaId!);

  // SECTION Request company detail
  useEffect(() => {
    dispatch(sendGetCompanyDetail({ cia_id: companyId }));
  }, [ciaId]);

  return (
    <Fragment>
      <div className="bg-white border w-full h-full p-[40px] overflow-x-auto">
        {/* SECTION Company info */}
        <CompanyDetails />
        {/* !SECTION */}
        {/* SECTION Sites of the company */}
        <Sites />
        {/* !SECTION */}
        {/* SECTION Files of the company */}
        <DataTableUploadFiles companyId={companyId} />
        {/* !SECTION  */}
      </div>
    </Fragment>
  );
};

export default CompanyDetailsPage;
