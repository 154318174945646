export const findMax = (data: number[]) => {
  console.log('data received:', data);
  let max = data[0];

  data?.forEach((dataPoint) => {
    if (dataPoint > max) max = dataPoint;
  });

  return max + 50;
};

export const findMin = (data: number[]) => {
  let min = data[0];

  data?.forEach((dataPoint) => {
    if (dataPoint < min) min = dataPoint;
  });

  return min - 50 < 0 ? 0 : min - 50;
};

export const formatNumberToFiveDigits = (number: number) => {
  let numStr = number.toString();
  let [integerPart, decimalPart] = numStr.split('.');

  if (integerPart.length + decimalPart.length > 5) {
    return parseFloat(numStr.substring(0, 5));
  }

  if (!decimalPart) {
    decimalPart = '';
  }

  let combined = integerPart + decimalPart;

  while (combined.length < 5) {
    combined += '0';
  }

  integerPart = combined.substring(0, integerPart.length);
  decimalPart = combined.substring(integerPart.length);

  let result = integerPart + '.' + decimalPart;

  return result;
};
