import { Chart } from 'chart.js';
import moment from 'moment';
import {
  MutableRefObject,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { AVERAGING_MODE } from '../config/enum';

type GraphContextProps = {
  children: ReactNode;
};

type ChartProviderProps = {
  isLiveMode: boolean;
  startDateFilter: number;
  endDateFilter: number;
  areDatesValid: boolean;
  chart: MutableRefObject<Chart<'line'> | null>;
  zoomState: {
    x: { max: number | string | null; min: number | null };
    y: { max: number | string | null; min: number | null };
  };
  setIsLiveMode: (value: boolean) => void;
  setStartDateFilter: (value: number) => void;
  setEndDateFilter: (value: number) => void;
  syncZoom: (chart: any) => void;
  resetZoom: () => void;
  averageMode: AVERAGING_MODE;
  setAverageMode: (value: AVERAGING_MODE) => void;
};

export const ChartContext = createContext<ChartProviderProps>(
  {} as ChartProviderProps
);

export default ({ children }: GraphContextProps) => {
  const [isLiveMode, setIsLiveMode] = useState<boolean>(true);
  const [averageMode, setAverageMode] = useState(AVERAGING_MODE.NONE);
  const [startDateFilter, setStartDateFilter] = useState<number>(
    moment().tz('America/Denver').startOf('day').unix() * 1000
  );

  const [zoomState, setZoomState] = useState<{
    x: { max: number | string | null; min: number | null };
    y: { max: number | string | null; min: number | null };
  }>({
    x: { min: null, max: null },
    y: { min: null, max: null }
  });

  const [endDateFilter, setEndDateFilter] = useState<number>(
    new Date().getTime()
  );

  const chart = useRef<Chart<'line'> | null>(null);
  const areDatesValid = useMemo(
    () =>
      !isNaN(startDateFilter) &&
      !isNaN(endDateFilter) &&
      endDateFilter > startDateFilter,
    [startDateFilter, endDateFilter]
  );

  useEffect(() => {
    const chartInstance = chart.current;

    if (chartInstance) {
      syncZoom(chartInstance);
    }
  }, []);

  const syncZoom = (chart: any) => {
    setZoomState({
      x: {
        min: chart.scales.x.min,
        max: chart.scales.x.max
      },
      y: {
        min: chart.scales.y.min,
        max: chart.scales.y.max
      }
    });
  };

  const resetZoom = () => {
    setZoomState({
      x: { min: 0, max: 1200 },
      y: { min: 0, max: 1200 }
    });
  };

  useEffect(() => {
    const chartInstance = chart.current;

    if (chartInstance) {
      syncZoom(chartInstance);
    }
  }, []);

  return (
    <ChartContext.Provider
      value={{
        isLiveMode,
        setIsLiveMode,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        areDatesValid,
        chart,
        syncZoom,
        zoomState,
        resetZoom,
        averageMode,
        setAverageMode
      }}>
      {children}
    </ChartContext.Provider>
  );
};
