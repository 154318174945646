import { Fragment, useState } from 'react';
import { ICompany, IMdp, ISite } from './interfaces';
import Collapse from '../../components/Collapse';
import mdpIcon from './../../assets/img/mdp_icon.png';
import MDPDetail from './MdpDetail';
import { ModalMiddle } from '../../components/Modal';
import MDPForm, { IMdpForm } from '../../components/Form/MDPForm';
import { useDispatch } from 'react-redux';
import {
  deleteMdp,
  sendCreateMDP,
  sendGetCompanyDetail,
  sendUpdateMDP,
  setLoading
} from '../../actions/electripure';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Mdps({
  mdps,
  site,
  company
}: {
  mdps: IMdp[];
  site: ISite;
  company: ICompany;
}) {
  const [showModalCreateMdp, setShowModalCreateMdp] = useState(false);
  const [showModalEditMdp, setShowModalEditMdp] = useState(false);
  const [mdpSelected, setMdpSelected] = useState({} as IMdp);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function submitCreateMdp(mdp: Partial<IMdpForm>) {
    dispatch(
      sendCreateMDP({
        ...mdp,
        gmt: undefined,
        gmt_id: mdp.gmt,
        site_id: site.id,
        company_id: company.id
      })
    );
  }

  function submitUpdateMdp(mdp: Partial<IMdpForm>) {
    dispatch(
      sendUpdateMDP({
        ...mdp,
        gmt: undefined,
        gmt_id: mdp.gmt,
        company_id: company.id
      })
    );
  }

  const deleteItem = async () => {
    if (mdpSelected.applianceID) {
      const success = await deleteMdp({
        meterId: mdpSelected.applianceID,
        cid: company.id
      });
      if (!success)
        return toast('Error occurred while deleting the MDP', {
          type: 'error',
          position: 'bottom-right'
        });

      toast('MDP deleted successfully', {
        type: 'success',
        position: 'bottom-right'
      });

      setShowModalEditMdp(false);

      return dispatch(sendGetCompanyDetail({ cia_id: company.id }));
    }
  };
  return (
    <div className="w-full">
      {/* SECTION Line */}
      <div className="flex justify-center items-center mb-[20px]">
        <p className="text-xl">MDPs</p>
        <hr className="ml-[10px] w-[100%]" />
        <button
          className="w-[250px] border h-[50px] ml-[10px]"
          onClick={() => setShowModalCreateMdp(!showModalCreateMdp)}>
          + Add New MDP
        </button>
      </div>
      {/* !SECTION */}
      {/* SECTION List MDPs */}
      {mdps.map((mdp: IMdp, _index: number) => (
        <Fragment>
          <Collapse
            key={_index}
            collapse={{
              showDefault: false,
              title: (
                <div>
                  <span>{mdp.MDPname}</span>
                  <span
                    className="cursor-pointer ml-[15px] text-[#00AEE8]"
                    onClick={() => {
                      setMdpSelected(mdp);
                      setShowModalEditMdp(true);
                    }}>
                    Edit MDP
                  </span>
                  {/* <span
                    className="cursor-pointer ml-[15px] text-[#00AEE8]"
                    onClick={() => {
                      navigate(
                        `/dashboard/company/${company.id}/mdp/${mdp.meterID}/alert/config`
                      );
                    }}>
                    Config Alerts
                  </span> */}
                </div>
              ),
              src: mdpIcon
            }}>
            <div className="flex">
              <div className="w-[100px]"></div>
              <MDPDetail mdp={mdp} />
            </div>
          </Collapse>
          <hr />
        </Fragment>
      ))}
      {/* !SECTION */}
      {/* SECTION PopUp */}
      <ModalMiddle
        show={showModalEditMdp}
        onClose={() => {
          setShowModalEditMdp(false);
        }}>
        <MDPForm
          button="Update"
          title="Update MDP"
          schematic={site.schedule_image}
          mdp={{
            amp: mdpSelected.MDP,
            name: mdpSelected.MDPname,
            id: mdpSelected.id,
            gmt: mdpSelected.gmt_id,
            applianceId: mdpSelected.applianceID,
            meterId: mdpSelected.applianceID,
            positionX: mdpSelected.location ? mdpSelected.location.x : -1,
            positionY: mdpSelected.location ? mdpSelected.location.y : -1,
            switchgear: mdpSelected.switchgear,
            transformer: mdpSelected.transformer
          }}
          onSubmit={submitUpdateMdp}
          onDelete={deleteItem}
          type="update"
        />
      </ModalMiddle>
      {/* !SECTION */}
      {/* SECTION PopUp */}
      <ModalMiddle
        show={showModalCreateMdp}
        onClose={() => {
          setShowModalCreateMdp(false);
        }}>
        <MDPForm
          schematic={site.schedule_image}
          mdp={{}}
          onSubmit={submitCreateMdp}
          type="create"
        />
      </ModalMiddle>
      {/* !SECTION */}
    </div>
  );
}

export default Mdps;
