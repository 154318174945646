import { Modal } from '@mui/material';
import { ButtonPrimary } from '../FormInput/Button';
import { useState } from 'react';

type ConfirmationModalProps = {
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

const ConfirmationModal = ({
  open,
  onSuccess,
  onCancel
}: ConfirmationModalProps) => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const handleClick = () => {
    setIsRequesting(true);
    new Promise((resolve) => {
      resolve(onSuccess());
    }).then(() => setIsRequesting(false));
  };

  return (
    <Modal open={open} onClose={onCancel}>
      <div className="w-[450px] bg-white absolute text-center px-3 py-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <span>
          Are you sure you want to delete this site? It will trigger the
          deletion of the devices as well.
        </span>
        <div className="flex gap-x-8 max-w-60 mx-auto mt-4 w-auto">
          <button
            onClick={handleClick}
            disabled={isRequesting}
            className="bg-red-800 text-white px-4 py-2 rounded-sm disabled:bg-red-500">
            Delete site
          </button>

          <button
            onClick={onCancel}
            className="bg-color-primary text-white px-4 py-2 rounded-sm">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
