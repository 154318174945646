import { Fragment, useState } from 'react';
import { ICompany, IMdp, ISite } from './interfaces';
import SiteDetail from './SiteDetail';
import MdpSchematic from './components/MdpSchematic';
import Mdps from './Mdps';
import mdpIcon from './../../assets/img/mdp_icon.png';
import { ModalMiddle } from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSite, sendCreateSite } from '../../actions/electripure';
import SiteForm, { ISiteForm } from '../../components/Form/SiteForm';
import { ElectripureState } from '../../interfaces/reducers';

function Sites() {
  const [showModalCreateSite, setShowModalCreateSite] = useState(false);
  const dispatch = useDispatch();
  const company: ICompany = JSON.parse(
    useSelector((state: ElectripureState) => state.companyDetails)
  );

  company.sites = company.sites ?? [];

  async function submitCreateSite(site: Partial<ISiteForm>) {
    const success = await dispatch(
      sendCreateSite({
        company_id: company.id,
        name: site.name,
        address: site.address,
        address2: site.address2,
        city: site.city,
        state: site.state,
        zip: site.zip,
        payment: site.payment,
        manager_fullname: site.manager_fullname,
        manager_email: site.manager_email,
        manager_phone: site.manager_phone,
        logo: site.logo_id,
        schematic: site.schematic_id
      })
    );

    if (success) setShowModalCreateSite(false);
  }

  // const handleDeleteSite = async () => {
  //   try {
  //     const success = await deleteSite({siteId: })
  //   } catch (error) {
      
  //   }
  // }
  
  return (
    <Fragment>
      {/* SECTION Line */}
      <div className="flex justify-center items-center my-[20px]">
        <p className="text-xl">Sites</p>
        <hr className="ml-[10px] w-[100%]" />
        <button
          className="w-[200px] border h-[50px] ml-[10px]"
          onClick={() => setShowModalCreateSite(!showModalCreateSite)}>
          + Add New Site
        </button>
      </div>
      {/* !SECTION */}
      {/* SECTION Sites */}
      <div className="border p-[30px]">
        {company.sites.map((site: ISite, index: number) => {
          return (
            <div>
              <SiteDetail key={index} site={site} company={company} />
              {/* SECTION More detail of site */}
              <div className="flex justify-between w-[100%]">
                {/* SECTION Image schematic */}
                <div className="w-[50%] px-[1%]">
                  <MdpSchematic
                    site={site}
                    pointConfig={{
                      height: 40,
                      width: 40,
                      src: mdpIcon,
                      onToltip(mdp: IMdp) {
                        return (
                          <div className="border-[0.5px] bg-white p-2 border-gray-900	rounded-sm	">
                            {mdp.MDPname}
                          </div>
                        );
                      }
                    }}
                  />
                </div>
                {/* !SECTION */}
                {/* SECTION MDPs */}
                <div className="w-[50%]">
                  <Mdps site={site} mdps={site.mdps} company={company} />
                </div>
                {/* !SECTION */}
              </div>
              {/* !SECTION */}
            </div>
          );
        })}
      </div>
      {/* !SECTION */}
      {/* SECTION PopUp */}
      <ModalMiddle
        show={showModalCreateSite}
        onClose={() => {
          setShowModalCreateSite(false);
        }}>
        <SiteForm
          site={{
            address: company.address,
            address2: company.address2,
            city: company.city,
            state: company.state,
            zip: company.zip,
            manager_phone: company.contact_phone,
            manager_fullname: company.contact_name,
            manager_email: company.contact_email
          }}
          onSubmit={submitCreateSite}
        />
      </ModalMiddle>
      {/* !SECTION */}
    </Fragment>
  );
}

export default Sites;
