import { Fragment } from 'react';
import { HeaderConfig, RowConfig, TableConfig } from './interfaces/datatable';
import { IoMdClose } from 'react-icons/io';

import './style.css';

function DataTable({
  config,
  onDelete
}: {
  config: TableConfig;
  onDelete: (id: number) => void;
}) {
  console.log('Config:', config);
  return (
    <Fragment>
      <div className="datatable-container">
        <table className="w-full">
          <thead>
            <tr>
              {config.headers.map((header: HeaderConfig, index: number) => {
                return (
                  <th
                    className={'p-[10px] datatable-header'}
                    key={'header' + index}>
                    <div className={'flex justify-start align-items'}>
                      {header.label}
                      <span
                        className={
                          header.sort != undefined
                            ? 'cursor-pointer ml-[5px]'
                            : 'hidden'
                        }
                        onClick={header.sort}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                          />
                        </svg>
                      </span>
                    </div>
                  </th>
                );
              })}
              <th className="text-center">Options</th>
            </tr>
          </thead>
          <tbody>
            {config.data.map((row: RowConfig, indexRow: number) => {
              return (
                <tr key={'row' + indexRow}>
                  {config.headers.map((header: HeaderConfig, index: number) => {
                    console.log({ row, header });
                    return (
                      <td
                        className="p-[10px]"
                        key={'row' + indexRow + '-cell' + index}>
                        {row[header.key].label}
                      </td>
                    );
                  })}
                  <td className="text-center">
                    <span
                      className="text-red-600 font-semibold cursor-pointer"
                      onClick={() => onDelete(row.id)}>
                      Delete
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default DataTable;
