import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  sendAddCompany,
  setJwt,
  setLoginToken,
  setTimestampTwoStepVerification
} from '../../../actions/electripure';
import {
  BasicCompanyInformationDataForm,
  CreateMDPDataForm,
  MainPointContactDataForm,
  SiteDetailDataForm,
  SiteManagerDataForm
} from '../../../interfaces/form';
import { ElectripureState } from '../../../interfaces/reducers';
import BasicCompanyInformationForm from '../../Form/BasicCompanyInformationForm';
import CreateMDPForm from '../../Form/CreateMDPForm';
import FinishCreateMDPForm from '../../Form/FinishCreateMDPForm';
import MainPointContactForm from '../../Form/MainPointContactForm';
import SiteDetailForm from '../../Form/SiteDetailForm';
import SiteManagerForm from '../../Form/SiteManagerForm';
import { ModalMiddle } from '../../Modal';
import logoUrl from './../../../assets/img/logo.png';
import isotipoUrl from './../../../assets/img/blue-ball-logo.png';
import DropdownSelector from './DropdownSelector';
import { CiaPermission } from '../../../routers/Permissions';

function NavigationSide({
  collapsed,
  onCollapsed,
  enableCollapsed,
  setStateNavigation
}: {
  collapsed: boolean;
  enableCollapsed: boolean;
  onCollapsed: (collapsed: boolean) => void;
  setStateNavigation: (value: any) => void;
}) {
  const dispatch = useDispatch();

  // SECTION States
  const [isShowModal, setShowModal] = useState(false);
  const [schematicImg, setSchematicImg] = useState<null | string>(null);
  const [siteDefaultDataForm, setSiteDefaultDataForm] = useState(`{
        "name": "",
        "address": "",
        "address2": "",
        "city": "",
        "state": "",
        "zip": "",
        "logo": "",
        "rate": "",
        "schematic": ""
    }`);
  const sideNavRef = useRef<HTMLDivElement>(null);

  const toastMessage = useSelector(
    (state: ElectripureState) => state.toastMessage
  );
  const [newCompanyRaw, setNewCompany] = useState(
    `{"id_user": ${localStorage.getItem('user_id')}}`
  );
  const [stepCreateCompany, setStepCreateCompany] = useState(1);
  const newCompany = JSON.parse(newCompanyRaw);
  // !SECTION
  useEffect(() => {
    if (toastMessage == 'Company created!') {
      // setStepCreateCompany(6);
      setShowModal(false);
      setStepCreateCompany(1);
    }
  }, [toastMessage]);

  function submitBasicCompanyInformationForm(
    data: BasicCompanyInformationDataForm
  ) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        basicInformation: {
          companyName: data.company,
          address: data.address,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          hasMeter: data.hasMeter,
          imgId: data.logo
        }
      })
    );
    setSiteDefaultDataForm(
      JSON.stringify({
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip
      })
    );
    console.log('Step 1', data);
    setStepCreateCompany(2);
  }

  function submitMainPointContactForm(data: MainPointContactDataForm) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        mainPointContact: {
          fullName: data.fullname,
          email: data.email,
          cellPhone: data.cellphone
        }
      })
    );
    console.log('Step 2', data);
    setStepCreateCompany(3);
  }

  function submitSiteManagerForm(data: SiteManagerDataForm) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        siteManager: {
          fullName: data.fullname,
          email: data.email,
          cellPhone: data.cellphone
        }
      })
    );
    console.log('Step 3', data);
    setStepCreateCompany(4);
  }

  function submitSiteDetailForm(data: SiteDetailDataForm) {
    setSchematicImg(data.schematicBase64);
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        siteDetails: {
          siteName: data.name,
          address: data.address,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          paymentSchedule: data.rate,
          imgId: data.logo,
          imgSchematic: data.schematic
        }
      })
    );
    console.log('Step 4', data);
    setStepCreateCompany(5);
  }

  function submitCreateMDPForm(data: CreateMDPDataForm[]) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        MDP: data.map((mdpData: CreateMDPDataForm) => {
          return {
            siteName: mdpData.name,
            meterID: mdpData.meterId,
            applianceID: mdpData.applianceId,
            MDP: mdpData.ampCap,
            gmt_id: mdpData.gmt,
            switchgear: mdpData.switchgearCap,
            transformer: mdpData.transformer,
            location: mdpData.location
          };
        })
      })
    );
    console.log('Step 5', data);
    dispatch(
      sendAddCompany({
        ...newCompany,
        MDP: data.map((mdpData: CreateMDPDataForm) => {
          return {
            siteName: mdpData.name,
            meterID: mdpData.meterId,
            applianceID: mdpData.applianceId,
            MDP: mdpData.ampCap,
            gmt_id: mdpData.gmt,
            switchgear: mdpData.switchgearCap,
            transformer: mdpData.transformer,
            location: mdpData.location
          };
        })
      })
    );
    setShowModal(false);
    setStepCreateCompany(1);
  }

  function previousStepCreateCompany() {
    setStepCreateCompany(stepCreateCompany - 1);
  }

  return (
    <div
      ref={sideNavRef}
      className="w-full h-full flex justify-start items-center flex-col shadow-md border-r border-color-black-light">
      <div className="w-full px-[10px] py-[15px]">
        <img
          src={collapsed ? isotipoUrl : logoUrl}
          className="h-[40px] m-auto"></img>
      </div>
      <div className={`w-full ${collapsed ? 'block' : 'hidden'}`}>
        <CiaPermission role="create_company">
          <div className="w-full flex justify-center item-center">
            <div
              className="w-[48px] h-[48px] text-[32px] rounded-full text-center bg-[#D9D9D9] cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}>
              <span className="text-black">&#43;</span>
            </div>
          </div>
        </CiaPermission>
      </div>
      <div className={`w-full ${collapsed ? 'hidden' : 'block'}`}>
        <DropdownSelector
          onCreateCompany={() => {
            setShowModal(true);
          }}
        />
      </div>
      {/* SECTION Bottom Icon */}
      <div className="w-full px-[10px] py-[15px] absolute bottom-0 left-0 flex justify-center">
        {!collapsed ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              onClick={() => {
                onCollapsed(true);
              }}
              className="w-6 h-6 cursor-pointer">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
              />
            </svg>
          </>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            onClick={() => {
              onCollapsed(false);
            }}
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
            />
          </svg>
        )}
      </div>
      {/* !SECTION */}

      {/* SECTION Modals */}
      <ModalMiddle
        show={isShowModal}
        onClose={() => {
          setShowModal(false);
          setStepCreateCompany(1);
        }}>
        {stepCreateCompany == 1 ? (
          <BasicCompanyInformationForm
            onSubmit={submitBasicCompanyInformationForm}
          />
        ) : stepCreateCompany == 2 ? (
          <MainPointContactForm
            onSubmit={submitMainPointContactForm}
            onPrevious={previousStepCreateCompany}
          />
        ) : stepCreateCompany == 3 ? (
          <SiteManagerForm
            onSubmit={submitSiteManagerForm}
            onPrevious={previousStepCreateCompany}
          />
        ) : stepCreateCompany == 4 ? (
          <SiteDetailForm
            defaultData={JSON.parse(siteDefaultDataForm)}
            onSubmit={submitSiteDetailForm}
            onPrevious={previousStepCreateCompany}
          />
        ) : stepCreateCompany == 5 ? (
          <CreateMDPForm
            onSubmit={submitCreateMDPForm}
            onPrevious={previousStepCreateCompany}
            schematicImg={schematicImg}
          />
        ) : (
          <div></div>
        )}
      </ModalMiddle>
      {/* !SECTION */}
    </div>
  );
}

export default NavigationSide;
